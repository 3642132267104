<template >
    <legalLayout>
        <template #content >
                <VueMarkdown class="mark-down" :source="src" ></VueMarkdown>
        </template>
    </legalLayout>
</template>
<script>
import VueMarkdown from 'vue-markdown-render';
import legalLayout from '../legal-layout.vue';
export default {
    components : {
        legalLayout,
        VueMarkdown
    },
    data(){
        return{
            src : `
# Agreement for Recovery Services

This Agreement is made and entered into as of [Date], by and between:

**Instaswiss LLC**, a company specializing in assisting U.S. residents with recovering funds and surpluses owed to them, with its principal place of business at [Company Address] ("Service Provider"),

and

**[Client’s Name]**, residing at [Client’s Address] ("Client").

## Table of Contents
1. [Services Provided](#services-provided)
2. [Commission](#commission)
3. [Payment Terms](#payment-terms)
4. [Responsibilities of the Client](#responsibilities-of-the-client)
5. [Confidentiality](#confidentiality)
6. [Termination](#termination)
7. [Governing Law](#governing-law)
8. [Entire Agreement](#entire-agreement)

## 1. Services Provided
The Service Provider agrees to assist the Client in recovering funds and surpluses owed to the Client. This includes identifying potential claims, submitting necessary documentation, and negotiating settlements on behalf of the Client.

## 2. Commission
In consideration for the services rendered, the Client agrees to pay the Service Provider a commission based on the recovered funds. The commission rate shall be [up to 30%] of the total amount recovered. The specific commission rate applicable to this agreement will be [insert specific rate if known].

## 3. Payment Terms
The commission shall be due and payable upon receipt of the recovered funds by the Client. The Service Provider shall invoice the Client, and the Client agrees to pay the invoice within [number] days of receipt.

## 4. Responsibilities of the Client
The Client agrees to provide all necessary documentation and information required by the Service Provider to effectively perform the services. The Client also agrees to cooperate fully with the Service Provider throughout the recovery process.

## 5. Confidentiality
Both parties agree to maintain the confidentiality of all information exchanged under this Agreement and to use such information only for the purposes specified in this Agreement.

## 6. Termination
Either party may terminate this Agreement upon [number] days' written notice to the other party. In the event of termination, the Client shall remain liable for any commission due for funds recovered up to the date of termination.

## 7. Governing Law
This Agreement shall be governed by and construed in accordance with the laws of the State of [State], without regard to its conflict of law principles.

## 8. Entire Agreement
This Agreement constitutes the entire agreement between the parties and supersedes all prior agreements and understandings, whether written or oral, relating to the subject matter hereof.

IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the date first above written.

**Instaswiss LLC**
`
        }
    }
}
</script>
<style >
    .mark-down {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .mark-down *{
        text-align: left;
    }
    .mark-down ol,.mark-down ul{
        padding-left: 8%;
        box-sizing: border-box;
    }
</style>