<template>
    <footer>
        <div class="footer-header">
            <h1 class="footer-header-titel">Instaswiss</h1>
            <div class="social-media" style="color: white;">
                <a target="_blank" href="https://www.facebook.com/profile.php?id=61564958785863&mibextid=LQQJ4d">
                    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_132_4)">
                            <path
                                d="M16 8.04902C16 3.60302 12.418 -0.000976562 8.00005 -0.000976562C3.58005 2.34375e-05 -0.00195312 3.60302 -0.00195312 8.05002C-0.00195312 12.067 2.92405 15.397 6.74805 16.001V10.376H4.71805V8.05002H6.75005V6.27502C6.75005 4.25802 7.94505 3.14402 9.77205 3.14402C10.648 3.14402 11.563 3.30102 11.563 3.30102V5.28102H10.554C9.56105 5.28102 9.25105 5.90202 9.25105 6.53902V8.04902H11.469L11.115 10.375H9.25005V16C13.074 15.396 16 12.066 16 8.04902Z"
                                fill="#77BECA" />
                        </g>
                        <defs>
                            <clipPath id="clip0_132_4">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <a target="_blank" href="https://www.instagram.com/instaswiss.us/">
                    <svg width="24" height="24" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_132_6)">
                            <path
                                d="M7.5 5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5C5 8.16304 5.26339 8.79893 5.73223 9.26777C6.20107 9.73661 6.83696 10 7.5 10C8.16304 10 8.79893 9.73661 9.26777 9.26777C9.73661 8.79893 10 8.16304 10 7.5C10 6.83696 9.73661 6.20107 9.26777 5.73223C8.79893 5.26339 8.16304 5 7.5 5Z"
                                fill="#77BECA" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.5 0C3.30653 0 2.16193 0.474106 1.31802 1.31802C0.474106 2.16193 0 3.30653 0 4.5L0 10.5C0 11.6935 0.474106 12.8381 1.31802 13.682C2.16193 14.5259 3.30653 15 4.5 15H10.5C11.6935 15 12.8381 14.5259 13.682 13.682C14.5259 12.8381 15 11.6935 15 10.5V4.5C15 3.30653 14.5259 2.16193 13.682 1.31802C12.8381 0.474106 11.6935 0 10.5 0L4.5 0ZM4 7.5C4 6.57174 4.36875 5.6815 5.02513 5.02513C5.6815 4.36875 6.57174 4 7.5 4C8.42826 4 9.3185 4.36875 9.97487 5.02513C10.6313 5.6815 11 6.57174 11 7.5C11 8.42826 10.6313 9.3185 9.97487 9.97487C9.3185 10.6313 8.42826 11 7.5 11C6.57174 11 5.6815 10.6313 5.02513 9.97487C4.36875 9.3185 4 8.42826 4 7.5ZM11 4H12V3H11V4Z"
                                fill="#77BECA" />
                        </g>
                        <defs>
                            <clipPath id="clip0_132_6">
                                <rect width="15" height="15" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                </a>





            </div>
        </div>
        <div class="sections">
            <div  @click="$router.replace('/contact/privacy-policy')"   class="read">
                <p class="read-header">Privacy Policy</p>
                <p class="read-link">Read our full privacy policy here.</p>
            </div>
            <div @click="$router.replace('/contact/terms-conditions')" class="read">
                <p class="read-header">Terms and Conditions</p>
                <p class="read-link">Review our terms and conditions.</p>
            </div>
        </div>
    </footer>
</template>
<script>

export default {
    data() {
        return {

        }
    }
}
</script>
<style scoped>
footer {
    

    width: 100%;

    background-color: var(--dark-blue);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 30px;

    padding: 5%;
    box-sizing: border-box;
}

footer .footer-header-titel {
    color: white;
}

footer .footer-header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer .social-media {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 10px;
}

footer .sections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    cursor: pointer;

}

footer .sections .read {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

footer .sections .read-header {
    font-size: 2vw;
    font-weight: 600;
    text-decoration: underline;
}

footer .sections .read-link {
    font-size: 1.5vw;
}
</style>