<template>
    <nav class="list-header">
        <p>{{ TitelSection }}</p>
        <div class="list-header-extra" >
            <slot name="extra" ></slot>
        </div>
    </nav>
</template>
<script>
export default {
    props : ["TitelSection"]
}
</script>
<style scoped>
.list-header{
    
    height: 8%;
    min-height: 60px;
    width: 100%;

    background-color: #023047;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 2%;
    box-sizing: border-box;
}
.list-header p{
    font-size: 1em;
    color: #8ecae6;
}
.list-header .list-header-extra{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    width: 50%;
    margin-left: auto;

    gap: 10px;

}
</style>