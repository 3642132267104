<template>
    <nav v-if="widthLarge" class="navbars index-lage-nav">
        <p  class="logo"><router-link to="/home" >Instaswiss</router-link></p>
        <div class="links">
            <router-link class="link" to="/home">Home</router-link>
            <router-link class="link" to="/about">About</router-link>
            <router-link class="link" to="/contact">Contact us</router-link>
        </div>
    </nav>
    <nav class="navbars index-thin-nav" v-else>
        <p class="logo"><router-link to="/home" >Instaswiss</router-link></p>
        <div @click="smallNavOpen = true" class="menu_icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_105_4588)">
                    <path
                        d="M20 17.5C20.3852 17.5002 20.7556 17.6486 21.0344 17.9144C21.3132 18.1802 21.479 18.5431 21.4975 18.9279C21.516 19.3127 21.3858 19.6898 21.1338 19.9812C20.8818 20.2726 20.5274 20.4558 20.144 20.493L20 20.5H4C3.61478 20.4998 3.24441 20.3514 2.96561 20.0856C2.68682 19.8198 2.52099 19.4569 2.50248 19.0721C2.48396 18.6873 2.61419 18.3102 2.86618 18.0188C3.11816 17.7274 3.47258 17.5442 3.856 17.507L4 17.5H20ZM20 10.5C20.3978 10.5 20.7794 10.658 21.0607 10.9393C21.342 11.2206 21.5 11.6022 21.5 12C21.5 12.3978 21.342 12.7794 21.0607 13.0607C20.7794 13.342 20.3978 13.5 20 13.5H4C3.60218 13.5 3.22064 13.342 2.93934 13.0607C2.65804 12.7794 2.5 12.3978 2.5 12C2.5 11.6022 2.65804 11.2206 2.93934 10.9393C3.22064 10.658 3.60218 10.5 4 10.5H20ZM20 3.5C20.3978 3.5 20.7794 3.65804 21.0607 3.93934C21.342 4.22064 21.5 4.60218 21.5 5C21.5 5.39782 21.342 5.77936 21.0607 6.06066C20.7794 6.34196 20.3978 6.5 20 6.5H4C3.60218 6.5 3.22064 6.34196 2.93934 6.06066C2.65804 5.77936 2.5 5.39782 2.5 5C2.5 4.60218 2.65804 4.22064 2.93934 3.93934C3.22064 3.65804 3.60218 3.5 4 3.5H20Z"
                        fill="#023047" />
                </g>
                <defs>
                    <clipPath id="clip0_105_4588">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>

        <Transition>
            <div v-if="smallNavOpen" class="links">
                <!-- :class="{ 'links-open': smallNavOpen, 'links-closed': !smallNavOpen }" -->
                <div @click="smallNavOpen = false" class="close_icon">
                    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                            fill="#023047" />
                    </svg>
                </div>

                <router-link class="link" to="/home">Home</router-link>
                <router-link class="link" to="/about">About</router-link>
                <router-link class="link" to="/contact">Contact us</router-link>

            </div>
        </Transition>
    </nav>
</template>
<script>
export default {
props : ["widthLarge"]   ,
data(){
        return {
            smallNavOpen : false
        }
    }
}
</script>
<!-- Commo, navbar styles -->
<style scoped >
    .navbars{
        position: fixed;
        left: 0;
        top: 0;
        height: var(--navbar-height);
        width: 100vw;
        background-color: var(--light-gray);

        z-index: 99;
    }
    .navbars .logo{
        font-size: var(--navbar-logo-size);
        font-weight: 500;
        color: var(--black-text-color);
    }
    .navbars .logo a{
        text-decoration: none;
        color: var(--black-text-color);

    }
</style>

<!-- Style for large navbars -->
<style scoped>
    .index-lage-nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2%;
        box-sizing: border-box;
    }
    
    .index-lage-nav .links{
        width: 100%;
        justify-content: flex-end;
        display: flex;
        align-items: center;

        gap: 2%;
        font-size: var(--navbar-links-size);
    }
    .index-lage-nav .links .link{
        text-decoration: none;
        color: var(--black-text-color);
    }
    .index-lage-nav .links .link:hover{
        text-decoration: underline;
    }
</style>

<!-- Style for mobile navbars -->
<style scoped >
.index-thin-nav{
    position: fixed;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 1%;
    box-sizing: border-box;
}

.index-thin-nav .links{
    position:fixed;
    top: 0;

    width: 100vw;
    height: 100vh;

    z-index: 99;

    background-color: white;

    transition: all 0.25s;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    gap: 2%;
    padding: 2%;
    box-sizing: border-box;

}
.index-thin-nav .links-open{
    left: 0;
}
.index-thin-nav .links-closed{
    left: 100%;
}
.index-thin-nav .links .close_icon{

    align-self: flex-end;
    flex-grow: 0.25;

}

.index-thin-nav .links .link{
    text-decoration: none;
    color: var( --black-text-color);
    font-size: 6vw;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>