<template>
    <indexLayout>

        <template #content>
            <section id="questions" class=" index-holder">
                <question :QuestionValue="questions[QuestionCurrentIndex].value"
                    :Question="questions[QuestionCurrentIndex].question" :QuestionNumber="QuestionCurrentIndex + 1">
                    <template #buttons>
                        <primaryButton TextContent="Yes" :positive="true" @click="answeredYes" />
                        <primaryButton TextContent="No" :positive="false" @click="answeredNo" />
                    </template>
                    <template v-if="!questions[QuestionCurrentIndex].forceAnswer" #dknButton>
                        <primaryButton TextContent="I don't remember" :positive="-1" @click="answeredDkn" />
                    </template>
                </question>
            </section>
        </template>
    </indexLayout>

</template>
<script>
import primaryButton from '@/components/primary-button.vue';
import question from '@/components/question.vue';
import { useInfoStore } from '@/store';
import { getAnswers, informBackEndForAnswers  } from '@/api.service';
import indexLayout from './index/index-layout.vue';
export default {
    name: "questions-view",
    data() {
        return {
            infoStore: useInfoStore(),
            questions: [
                {
                    question: "Is your complete name",
                    value: "",
                    userAnswer: null,
                    forceAnswer: true,
                },
                {
                    question: "Do you reside or have you ever resided in ",
                    value: "",
                    userAnswer: null,
                    forceAnswer: false,
                },
                {
                    question: "Have you ever done business with this company",
                    value: "",
                    userAnswer: null,
                    forceAnswer: false,
                },

            ],
            QuestionCurrentIndex: 0,

        }
    },
    components: {
        question,
        primaryButton,
        indexLayout
    },
    methods: {
        answeredYes() {
            this.questions[this.QuestionCurrentIndex].userAnswer = true;
            this.NextQuestion()
        },
        answeredDkn() {
            this.questions[this.QuestionCurrentIndex].userAnswer = -1;
            this.NextQuestion()
        },
        answeredNo() {
            this.questions[this.QuestionCurrentIndex].userAnswer = false;
            this.NextQuestion()
        },
        NextQuestion() {
            if (this.QuestionCurrentIndex < this.questions.length - 1) {
                this.QuestionCurrentIndex = this.QuestionCurrentIndex + 1;
            } else {
                this.processAnswer()
            }
        },
        async processAnswer() {
            await informBackEndForAnswers(this.questions, this.$route.params.token)
                .then(() => {
                    let trueCounter = 0;

                    for (let question of this.questions) {
                        if (question.userAnswer == true) {
                            trueCounter += 1;
                        }
                    }
                    if (trueCounter == 3) {
                        console.log("it's a match")
                        this.$router.push({ name: 'match' });
                        return
                    }
                    if (trueCounter == 2) {
                        console.log("someone will call them real quick")
                        this.$router.push({ name: 'callyou' });
                        return
                    }
                    this.$router.push({ name: 'notEligible' });
                })

        },
    },
    async beforeMount() {
        if("AuthToken" in window.localStorage){
            window.localStorage.removeItem("AuthToken")
        }
        const Token = this.$route.params.token;
        this.infoStore.Token = Token
        window.localStorage.setItem("AuthToken",Token)
        console.log(window.localStorage)
        await getAnswers(Token)
            .then((data) => {
                this.questions[0].value = data.first_name + " " + data.last_name;
                this.questions[1].value = data.town;
                this.questions[2].value = data.company;
            })
            .catch((error)=>{
                console.log("could not load the data maybe invalid token",error)
            })
    },
    beforeUnmount(){
        // AuthTokenDelete()
    }
}
</script>
<style>
.holder {
    height: 80vh;
    min-height: 600px;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #e9ecef;
}
</style>
<style scoped >
#questions{
    padding: 20px;
    box-sizing: border-box;
} 
</style>