<template>
    <indexLayout>
        <template #content>
            <section id="contact">
                <div id="informations">
                    <h1 class="titel">Get in touch with us</h1>
                    <div class="details">
                        <div class="detail">
                            <i>
                                <svg width="100%" height="100%" viewBox="0 0 20 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z"
                                        fill="#77BECA" />
                                </svg>
                            </i>
                            <p>instaswiss@gmail.com</p>
                        </div>
                        <div class="detail">
                            <i><svg width="100%" height="100%" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M1.88498 0.510903C2.05996 0.336191 2.27006 0.200661 2.50138 0.113293C2.7327 0.0259244 2.97994 -0.0112866 3.22672 0.00412564C3.47351 0.0195378 3.7142 0.0872213 3.93285 0.202691C4.15149 0.31816 4.34311 0.478779 4.49498 0.673903L6.28998 2.9799C6.61898 3.4029 6.73498 3.9539 6.60498 4.4739L6.05798 6.6639C6.0299 6.77734 6.03153 6.89611 6.0627 7.00873C6.09388 7.12136 6.15356 7.22405 6.23598 7.3069L8.69298 9.7639C8.77593 9.84649 8.87879 9.90627 8.99161 9.93745C9.10443 9.96864 9.2234 9.97017 9.33698 9.9419L11.526 9.3949C11.7826 9.33111 12.0504 9.32632 12.3091 9.38088C12.5679 9.43543 12.8109 9.54793 13.02 9.7099L15.326 11.5039C16.155 12.1489 16.231 13.3739 15.489 14.1149L14.455 15.1489C13.715 15.8889 12.609 16.2139 11.578 15.8509C8.93866 14.9235 6.54252 13.4127 4.56798 11.4309C2.58636 9.45665 1.07553 7.06087 0.147983 4.4219C-0.214017 3.3919 0.110983 2.2849 0.850983 1.5449L1.88498 0.510903Z"
                                        fill="#77BECA" />
                                </svg>
                            </i>
                            <p>+123457894</p>
                        </div>
                        <div class="detail">
                            <i>
                                <svg width="100%" height="100%" viewBox="0 0 34 40" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M32.015 24.98C33.3236 22.524 34.0055 19.7829 34 17C34 7.611 26.389 0 17 0C7.61103 0 2.62117e-05 7.611 2.62117e-05 17C-0.00702948 21.0103 1.41057 24.8928 4.00003 27.955L4.02003 27.98L4.03803 28H4.00003L14.088 38.71C14.462 39.107 14.9132 39.4233 15.4139 39.6394C15.9146 39.8556 16.4542 39.9672 16.9995 39.9672C17.5449 39.9672 18.0845 39.8556 18.5852 39.6394C19.0859 39.4233 19.5371 39.107 19.911 38.71L30 28H29.962L29.978 27.981L29.98 27.979C30.052 27.893 30.1237 27.8063 30.195 27.719C30.8888 26.8666 31.4984 25.95 32.015 24.98ZM17.005 23.499C15.4137 23.499 13.8876 22.8669 12.7624 21.7416C11.6372 20.6164 11.005 19.0903 11.005 17.499C11.005 15.9077 11.6372 14.3816 12.7624 13.2564C13.8876 12.1311 15.4137 11.499 17.005 11.499C18.5963 11.499 20.1224 12.1311 21.2477 13.2564C22.3729 14.3816 23.005 15.9077 23.005 17.499C23.005 19.0903 22.3729 20.6164 21.2477 21.7416C20.1224 22.8669 18.5963 23.499 17.005 23.499Z"
                                        fill="#77BECA" />
                                </svg>
                            </i>
                            <p>New york</p>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="GetInTouch" action="" id="form-message">
                    <div class="holding-two">
                        <div class="input-holder" >
                            <label for="">First Name</label>
                            <input placeholder="First name..." v-model="contact_form.customer.first_name" required type="text">
                        </div>
                        <div class="input-holder" >
                            <label for="">Last Name</label>
                            <input placeholder="Last name..." v-model="contact_form.customer.last_name" required type="text">
                        </div>
                    </div>
                    
                    <div class="input-holder" >
                        <label for="">Phone Number</label>
                        <input placeholder="Phone number..." v-model="contact_form.customer.phone_number" required type="tel">
                    </div>
                    <div class="holding-two">
                        <div class="input-holder" >
                            <label for="">Town</label>
                            <input placeholder="Town..." v-model="contact_form.customer.town" required type="text">
                        </div>
                        <div class="input-holder" >
                            <label for="">Company</label>
                            <input placeholder="Company you work with..." v-model="contact_form.customer.company" required type="text">
                        </div>
                    </div>
                    <div class="input-holder" >
                        <label for="">Email</label>
                        <input placeholder="Email..." v-model="contact_form.customer.email" required type="email">
                    </div>
                    <div class="input-holder message-textarea" >
                        <label for="">Message</label>
                        <textarea  placeholder="Message..." v-model="contact_form.message" maxlength="256"  ></textarea>
                    </div>
                    <div class="terms-condition" > 
                        <label for="">I aggree with your <router-link target="_blank" to="/contact/terms-conditions" >Terms and conditions</router-link> and <router-link target="_blank" to="/contact/privacy-policy" >Privacy Policy</router-link> </label>
                        <input class="checkbox" type="checkbox" required :value="false">
                    </div>
                    <!-- <button class="contact-button"  >Send Message</button> -->
                    <secondActionButton   width="100%" class="contact-button" InnerText="Send Message" />
                </form>
            </section>

        </template>
    </indexLayout>
</template>
<script>
import indexLayout from '../index-layout.vue';
import secondActionButton from '@/components/index/second-action-button.vue';
import { getInTouch } from '@/api.service';
// import ScrollReveal from 'scrollreveal';
export default {
    components: {
        indexLayout,
        secondActionButton
    },
    data() {
        return {
            contact_form : {
                customer : {
                    first_name : "",
                    last_name:"",
                    phone_number : "",
                    email : "",
                    town : "",
                    company : ""
                },
                message : ""
            }
        }
    },
    mounted() {

        // const sr = ScrollReveal();

        // sr.reveal('.at_instaswiss', {
        //     duration: 500,
        //     origin: 'bottom',
        //     distance: '300px',
        //     easing: 'ease-in-out',
        // });

    },
    methods : {
        async GetInTouch(){
            console.log("submitting with",this.contact_form)
            await getInTouch(this.contact_form)
            .then((data)=>{
                console.log("data",data)
            })
            .catch((error)=>{
                console.log("error",error)
            })
        }
    }
}
</script>

<script setup >
import { useHead } from '@vueuse/head';
useHead({
  title: 'Contact Us | Instaswiss',
  meta: [
    {
      name: 'description',
      content: 'Get in touch with Instaswiss. Contact us for any inquiries or support.'
    },
    {
      property: 'og:title',
      content: 'Contact Us | Instaswiss'
    },
    {
      property: 'og:description',
      content: 'Get in touch with Instaswiss. Contact us for any inquiries or support.'
    },
    {
      property: 'og:type',
      content: 'website'
    }
  ]
});
</script>
<!-- Mission -->
<style scoped>
#contact{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto ;

    width: 100%;

    
    padding: 8%;
    box-sizing: border-box;

    background-color: rgb(235, 235, 235);
}
@media screen and (max-width: 850px){
    #contact{
        display: grid;
        grid-template-columns: 1fr ;
        grid-template-rows: auto ;

        width: 100%;
        
        padding: 8%;
        gap: 5%;
        box-sizing: border-box;
        padding-bottom: var(--footer-spacing);
        background-color: rgb(235, 235, 235);
    }
}
</style>

<style scoped>
#contact #informations{

width: 100%;

display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;

}
#contact #informations .details{
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
}
#contact #informations .titel{
font-size: 4vw;
line-height: 20vh;
}
#contact #informations .details .detail{
display: flex;
justify-content: flex-start;
align-items: center;

gap: 15px;
box-sizing: border-box;

font-size: 2vw;
}
#contact #informations .details .detail i{
width: 35px;
height: 35px;    
}
#contact #informations .details .detail p{
font-size:2vw;
width: 100%;
}
@media screen and (max-width: 850px){
    #contact #informations .titel{
        font-size: 6vw;
        line-height: 10vh;
    }
    #contact #informations .details .detail p{
font-size:4vw;
width: 100%;
}
}
</style>

<style scoped >
#form-message{
    height: 80vh;
    min-height: 800px;

    background-color: white;
    border-radius: 26px;

    -webkit-box-shadow:0px 10px 39px 10px rgba(62,66,66,0.22);
    -moz-box-shadow: 0px 10px 39px 10px rgba(62,66,66,0.22);
    box-shadow: 0px 10px 39px 10px rgba(62,66,66,0.22);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 8%;
    gap: 10px;
    box-sizing: border-box;
}
#form-message .input-holder{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
}
#form-message .input-holder input{
    height: 50px;
    background-color: transparent;
    border-radius: 10px;
    border: solid 1px var(--dark-bleu);
    width: 100%;
    padding: 5%;
    box-sizing: border-box;

    -webkit-box-shadow:0px 3px 20px 2px rgba(62,66,66,0.22);
    -moz-box-shadow: 0px 3px 20px 2px rgba(62,66,66,0.22);
    box-shadow: 0px 3px 20px 2px rgba(62,66,66,0.22);
    
}
#form-message .holding-two{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 5%;
    box-sizing: border-box;
    width: 100%;
}
#form-message .terms-condition {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

}
#form-message .terms-condition .checkbox{

    width: 16px;
    height: 16px;
}
#form-message .terms-condition label{
    font-size: 16px;
}
#form-message .input-holder input:invalid{
    border: solid 1px red;
}
#form-message .terms-condition{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#form-message .input-holder  textarea{
    width: 100%;
    flex-grow: 1;
    -webkit-box-shadow:0px 3px 20px 2px rgba(62,66,66,0.22);
    -moz-box-shadow: 0px 3px 20px 2px rgba(62,66,66,0.22);
    box-shadow: 0px 3px 20px 2px rgba(62,66,66,0.22);
    border: solid 1px var(--dark-bleu);
    background-color: transparent;
    border-radius: 10px;
    padding: 5%;
    box-sizing: border-box;
    font-family: Avenir, Helvetica, Arial, sans-serif;

}
#form-message .message-textarea{
    height: 100%;
}
#form-message .input-holder label {
    font-size: 24px;
}
#form-message .contact-button{
    height: fit-content;
    border-radius: 10px;
    cursor: pointer;
}


</style>

