<template>
    <indexLayout>
        <template #content >
            <div id="main">
                <div class="fof">
                    <h1>Error 404</h1>
                </div>
                <p>The content you are looking for does not exist...</p>
            </div>
        </template>
    </indexLayout>
    
</template>
<script>
import indexLayout from '../index-layout.vue';
export default {
    name: 'page-404',
    methods: {

    },
    components : {
        indexLayout
    }
}
</script>
<style scoped>
#main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 80vh;
    text-align: center;
}

.fof {
    display: table-cell;
    vertical-align: middle;
}

.fof h1 {
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type .5s alternate infinite;
}

@keyframes type {
    from {
        box-shadow: inset -3px 0px 0px #888;
    }

    to {
        box-shadow: inset -3px 0px 0px transparent;
    }
}
</style>