<template lang="">
    <div class="backoffice-holder"  :style="{ marginTop: widthLarge ? '0px' : '50px' }"    >
        <navbar :windowWidth="widthLarge" v-if="!hideNav" />
        <slot name="content" ></slot>
    </div>
</template>
<script>
import navbar from './navbar/navbar.vue';
export default {
    components : {
        navbar
    },
    props : ["hideNav"],
    data(){
        return{
            widthLarge : window.innerWidth >= 850 ? true : false,
        }
    },
    mounted(){
        window.addEventListener('resize', ()=>{
            this.widthLarge = window.innerWidth >= 850 ? true : false;
        });
    },
}
</script>
<style>
    .backoffice-base-holder{
        height: 100%;
        min-height: 600px;
        width: 80%;
        margin-left: 20%;
        
        box-sizing: border-box;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        background-color: #e9ecef;

    }
    @media screen and (max-width: 850px){
        .backoffice-base-holder{
        width: 100%;
        margin-left: 0%;
    }
    }
</style>
<style scoped >
    

    .backoffice-holder{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: start;

        height: auto;
        width: 100vw;


        overflow-y:hidden;
    }
</style>