<template>
    <div class="backoffice-base-holder">
        <backofficeLayout :hideNav="false">

            <template #content>
                <section class="form-holder">
                    <sectionHeader TitelSection="Interract with customer" />
                    <form @submit.prevent="create" class="backoffice-form">
                        <p class="form-title">Interract with customer</p>
                        <div class="input-container">
                            <label for="">Phone Number</label>
                            <input style="cursor:not-allowed;" :disabled="true" v-model="customer.phone_number"
                                type="text" placeholder="Enter phone number">
                        </div>
                        <div class="input-container">
                            <label for="">First name</label>
                            <input style="cursor:not-allowed;" v-model="customer.first_name" :disabled="true"
                                type="text" placeholder="Enter first name">
                        </div>
                        <div class="input-container">
                            <label for="">Last Name</label>
                            <input style="cursor:not-allowed;" v-model="customer.last_name" :disabled="true" type="text"
                                placeholder="Enter last name">
                        </div>
                        <div class="input-container">
                            <label for="">Town</label>
                            <input style="cursor:not-allowed;" v-model="customer.town" :disabled="true" type="text"
                                placeholder="Enter town">
                        </div>
                        <div class="input-container">
                            <label for="">Company</label>
                            <input style="cursor:not-allowed;" v-model="customer.company" :disabled="true" type="text"
                                placeholder="Enter company">
                        </div>
                        <div class="input-container">
                            <label for="">Status</label>
                            <input style="cursor:not-allowed;" v-model="customer.match.value" :disabled="true" type="text"
                                placeholder="Enter company">
                        </div>

                        <div class="form-bottom-buttons">
                            <!-- <primaryButton :positive="true"  TextContent="Save changes" /> -->
                            <primaryButton :positive="false" TextContent="Delete" @click="DeleteCustomer" />
                        </div>
                    </form>

                    <section v-if="customer.match.code == 'C' || customer.match.code == 'REF' || customer.match.code == 'P' " class="section-content" id="documents">
                        <sectionHeader TitelSection="Documents"></sectionHeader>
                        <div class="documents-card-holder" >
                            <div @click="RetreiveDocument('ssc')"  class="file-card" >
                                <p>SSC</p>
                                <i>
                                    <svg width="20" height="20" viewBox="0 0 12 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4 3.5C3.86739 3.5 3.74021 3.55268 3.64645 3.64645C3.55268 3.74021 3.5 3.86739 3.5 4V8C3.5 8.13261 3.55268 8.25979 3.64645 8.35355C3.74021 8.44732 3.86739 8.5 4 8.5H8C8.13261 8.5 8.25979 8.44732 8.35355 8.35355C8.44732 8.25979 8.5 8.13261 8.5 8V7.75C8.5 7.55109 8.57902 7.36032 8.71967 7.21967C8.86032 7.07902 9.05109 7 9.25 7C9.44891 7 9.63968 7.07902 9.78033 7.21967C9.92098 7.36032 10 7.55109 10 7.75V8C10 8.53043 9.78929 9.03914 9.41421 9.41421C9.03914 9.78929 8.53043 10 8 10H4C3.46957 10 2.96086 9.78929 2.58579 9.41421C2.21071 9.03914 2 8.53043 2 8V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H4.25C4.44891 2 4.63968 2.07902 4.78033 2.21967C4.92098 2.36032 5 2.55109 5 2.75C5 2.94891 4.92098 3.13968 4.78033 3.28033C4.63968 3.42098 4.44891 3.5 4.25 3.5H4ZM6.75 3.5C6.55109 3.5 6.36032 3.42098 6.21967 3.28033C6.07902 3.13968 6 2.94891 6 2.75C6 2.55109 6.07902 2.36032 6.21967 2.21967C6.36032 2.07902 6.55109 2 6.75 2H9.25C9.44891 2 9.63968 2.07902 9.78033 2.21967C9.92098 2.36032 10 2.55109 10 2.75V5.25C10 5.44891 9.92098 5.63968 9.78033 5.78033C9.63968 5.92098 9.44891 6 9.25 6C9.05109 6 8.86032 5.92098 8.71967 5.78033C8.57902 5.63968 8.5 5.44891 8.5 5.25V4.56L7.28 5.78C7.13783 5.91248 6.94978 5.9846 6.75548 5.98117C6.56118 5.97775 6.37579 5.89903 6.23838 5.76162C6.10097 5.62421 6.02225 5.43882 6.01883 5.24452C6.0154 5.05022 6.08752 4.86217 6.22 4.72L7.44 3.5H6.75Z"
                                            fill="#77BECA" />
                                    </svg>
                                </i>
                                
                            </div>
                            <div @click="RetreiveDocument('company_document')" class="file-card" >
                                <p>Related Company</p>
                                <i>
                                    <svg width="20" height="20" viewBox="0 0 12 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4 3.5C3.86739 3.5 3.74021 3.55268 3.64645 3.64645C3.55268 3.74021 3.5 3.86739 3.5 4V8C3.5 8.13261 3.55268 8.25979 3.64645 8.35355C3.74021 8.44732 3.86739 8.5 4 8.5H8C8.13261 8.5 8.25979 8.44732 8.35355 8.35355C8.44732 8.25979 8.5 8.13261 8.5 8V7.75C8.5 7.55109 8.57902 7.36032 8.71967 7.21967C8.86032 7.07902 9.05109 7 9.25 7C9.44891 7 9.63968 7.07902 9.78033 7.21967C9.92098 7.36032 10 7.55109 10 7.75V8C10 8.53043 9.78929 9.03914 9.41421 9.41421C9.03914 9.78929 8.53043 10 8 10H4C3.46957 10 2.96086 9.78929 2.58579 9.41421C2.21071 9.03914 2 8.53043 2 8V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H4.25C4.44891 2 4.63968 2.07902 4.78033 2.21967C4.92098 2.36032 5 2.55109 5 2.75C5 2.94891 4.92098 3.13968 4.78033 3.28033C4.63968 3.42098 4.44891 3.5 4.25 3.5H4ZM6.75 3.5C6.55109 3.5 6.36032 3.42098 6.21967 3.28033C6.07902 3.13968 6 2.94891 6 2.75C6 2.55109 6.07902 2.36032 6.21967 2.21967C6.36032 2.07902 6.55109 2 6.75 2H9.25C9.44891 2 9.63968 2.07902 9.78033 2.21967C9.92098 2.36032 10 2.55109 10 2.75V5.25C10 5.44891 9.92098 5.63968 9.78033 5.78033C9.63968 5.92098 9.44891 6 9.25 6C9.05109 6 8.86032 5.92098 8.71967 5.78033C8.57902 5.63968 8.5 5.44891 8.5 5.25V4.56L7.28 5.78C7.13783 5.91248 6.94978 5.9846 6.75548 5.98117C6.56118 5.97775 6.37579 5.89903 6.23838 5.76162C6.10097 5.62421 6.02225 5.43882 6.01883 5.24452C6.0154 5.05022 6.08752 4.86217 6.22 4.72L7.44 3.5H6.75Z"
                                            fill="#77BECA" />
                                    </svg>
                                </i>
                                
                            </div>
                            <div @click="RetreiveDocument('id_card')" class="file-card" >
                                <p>ID Card</p>
                                <i>
                                    <svg width="20" height="20" viewBox="0 0 12 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4 3.5C3.86739 3.5 3.74021 3.55268 3.64645 3.64645C3.55268 3.74021 3.5 3.86739 3.5 4V8C3.5 8.13261 3.55268 8.25979 3.64645 8.35355C3.74021 8.44732 3.86739 8.5 4 8.5H8C8.13261 8.5 8.25979 8.44732 8.35355 8.35355C8.44732 8.25979 8.5 8.13261 8.5 8V7.75C8.5 7.55109 8.57902 7.36032 8.71967 7.21967C8.86032 7.07902 9.05109 7 9.25 7C9.44891 7 9.63968 7.07902 9.78033 7.21967C9.92098 7.36032 10 7.55109 10 7.75V8C10 8.53043 9.78929 9.03914 9.41421 9.41421C9.03914 9.78929 8.53043 10 8 10H4C3.46957 10 2.96086 9.78929 2.58579 9.41421C2.21071 9.03914 2 8.53043 2 8V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H4.25C4.44891 2 4.63968 2.07902 4.78033 2.21967C4.92098 2.36032 5 2.55109 5 2.75C5 2.94891 4.92098 3.13968 4.78033 3.28033C4.63968 3.42098 4.44891 3.5 4.25 3.5H4ZM6.75 3.5C6.55109 3.5 6.36032 3.42098 6.21967 3.28033C6.07902 3.13968 6 2.94891 6 2.75C6 2.55109 6.07902 2.36032 6.21967 2.21967C6.36032 2.07902 6.55109 2 6.75 2H9.25C9.44891 2 9.63968 2.07902 9.78033 2.21967C9.92098 2.36032 10 2.55109 10 2.75V5.25C10 5.44891 9.92098 5.63968 9.78033 5.78033C9.63968 5.92098 9.44891 6 9.25 6C9.05109 6 8.86032 5.92098 8.71967 5.78033C8.57902 5.63968 8.5 5.44891 8.5 5.25V4.56L7.28 5.78C7.13783 5.91248 6.94978 5.9846 6.75548 5.98117C6.56118 5.97775 6.37579 5.89903 6.23838 5.76162C6.10097 5.62421 6.02225 5.43882 6.01883 5.24452C6.0154 5.05022 6.08752 4.86217 6.22 4.72L7.44 3.5H6.75Z"
                                            fill="#77BECA" />
                                    </svg>
                                </i>
                            </div>
                        </div>
                        <div v-if=" customer.match.code == 'P'  || customer.match.code == 'REF' || customer.match.code == 'C' " class="documents-messaging">
                            <textarea maxlength="512" placeholder="The sms to send to the client when changing his status." v-model="document_check_message" name="" id=""></textarea>
                            <div class="documents-buttons">
                                <primaryButton :positive="true" TextContent="Validate Documents" @click="DocumentsBeforeCheck(false)" />
                                <primaryButton :positive="false" TextContent="Refuse Documents" @click="DocumentsBeforeCheck(true)" />
                            </div>
                        </div>
                    </section>

                    <sectionHeader TitelSection="Messages History">
                        <template #extra>
                            <primaryButton :handelClick="sendNewMessage" :positive="true"
                                TextContent="Send new message" />
                        </template>
                    </sectionHeader>
                    <div v-if="customer.messages.length != 0" class="mesages-list">
                        <div v-for="message,index in customer.messages" :key="index" class="message-box">
                            <p class="message-time">Sent at <span>{{ message.sent_at }}</span></p>
                            <div class="message-content">
                                {{ message.content }}
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p style="font-size: 18px;color:gray;font-weight: 300;text-align: left;margin-bottom: 50px;">No
                            messages have been sent yet.</p>
                    </div>


                </section>
            </template>
        </backofficeLayout>
    </div>
</template>
<script>
// import actionButton from '@/components/action-button.vue';
import backofficeLayout from '.././backoffice-layout.vue';
import primaryButton from '@/components/primary-button.vue';
import { useInfoStore } from '@/store';
import { createCustomer } from '@/api.service';
import { getCustomerByID,getToken,sendMessage,customerDelete,DocumentCheck } from '@/api.service';
import sectionHeader from '@/components/section-header.vue';
export default {
    name:"customer-edit",
    components: {
        backofficeLayout,
        primaryButton,
        sectionHeader
    },
    data(){
        return{
           customer : {
                first_name : null,
                last_name : null,
                town : null,
                company : null,
                phone_number : null,
                messages : [],
                match : {
                    value : null,
                    code : null
                }
           },
           document_check_message : "",
           infoStore : useInfoStore(),
        }
    },
    methods : {
        create(){
            if(this.first_name && this.last_name && this.town && this.company && this.phone_number){
                try{
                createCustomer({
                    "first_name" : this.first_name,
                    "last_name" : this.last_name,
                    "town" : this.town,
                    "company" : this.company,
                    "phone_number" : this.phone_number,
                })
                .then(data=>{
                    console.log("received",data);
                    this.$router.replace({name : 'backoffice-customers'})
                })

            }catch(err){
                console.log(err)
            }
            }
            return;
           
        },
        async sendNewMessage(){
            try{
                await sendMessage({
                    "id" : Number(this.$route.params.id)
                })
                .then(()=>{
                    this.$router.go()
                })
            }catch(err){
                console.log(err)
            }
        },
        async DeleteCustomer(){
            await customerDelete(this.$route.params.id)
            .then(()=>{
                this.$router.replace({name: "backoffice-customers"})
            })
        },
        async DocumentsBeforeCheck(decline){
            if(this.document_check_message == null || this.document_check_message == "" ){
                this.emitter.emit("sidebar-error",{
                    error : true,
                    description : "Please write a message to send first"
                })
                return;
            }
            try{
                await DocumentCheck({
                "message" : this.document_check_message,
                "valid" : !decline,
                "id" : this.$route.params.id
            })
            }catch(error){
                console.log(error)
            }
        },
        async RetreiveDocument(doc_type){
            const { href } = this.$router.resolve({name : "backoffice-customers-documents",params : {id : this.$route.params.id,document_type : doc_type}})
            window.open(href, '_blank');    
        }
        
    },
    beforeMount(){
        console.log("Entering edit customer root",this.$route.params.id)
        if(getToken() == null){
            this.$router.replace("/backoffice/login")
        }
        getCustomerByID(this.$route.params.id)
        .then(customer=>{
            this.customer = customer;
        })
    }
    
}
</script>
<style scoped>
.mesages-list{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    height: fit-content;
    /* min-height: 500px; */

    padding: 0% 2%;
    box-sizing: border-box;
    gap: 20px;

    padding-bottom: 50px;
}
.mesages-list .message-box{
    width: 80%;
    border-radius: 20px;
    min-height: 100px;

    text-align: start;
    padding: 1% 2%;
    box-sizing: border-box;
    background-color: white;

    width: 100%;
    word-break: break-word;
}
.mesages-list .message-box .message-time{
    border-bottom: solid 1px #023047;
    margin-bottom: 4px;
    color: #023047;
    font-size: 18px;
    font-weight: 500;


}
.list-header{
    
    height: 8%;
    min-height: 60px;
    width: 100%;

    background-color: #023047;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    padding: 2%;
    box-sizing: border-box;
}
.list-header p{
    font-size: 20px;
    color: #8ecae6;
}

.form-holder{
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    box-sizing: border-box;

    /* background-color: red */
    gap: 30px;
    box-sizing: border-box;

}
.backoffice-form {
    height: auto;
    width: 100%;
    
    background-color: white;
    margin: auto;
    display: block;
    padding: 1rem;
    max-width: 350px;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

    
}

.form-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.input-container input, .form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px 0;
}

.input-container input {
  background-color: #fff;
  padding: 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 300px;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.submit {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: #4F46E5;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  width: 100%;
  border-radius: 0.5rem;
  text-transform: uppercase;
}

.signup-link {
  color: #6B7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}

.signup-link a {
  text-decoration: underline;
}
.form-bottom-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 10px;
    box-sizing: border-box;
    gap: 2%;
}

</style>

<!-- documents section -->
<style scoped >
.section-content{
    width: 100%;
}
#documents .documents-card-holder{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 5%;
    box-sizing: border-box;
    gap: 10px;

}
#documents .documents-card-holder .file-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    gap: 5px;
    background-color: var(--dark-blue);
    color: white;
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;

    cursor: pointer;
}
#documents .documents-messaging{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 0% 5%;
    box-sizing: border-box ;
    gap: 20px;
    box-sizing: border-box;
}
#documents .documents-messaging textarea{
    width: 100%;
    height: 50px;

    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    border: none;
    -webkit-box-shadow:0px 3px 20px 2px rgba(62,66,66,0.22);
    -moz-box-shadow: 0px 3px 20px 2px rgba(62,66,66,0.22);
    box-shadow: 0px 3px 20px 2px rgba(62,66,66,0.22);
}
#documents .documents-messaging .documents-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    gap: 10px;
    box-sizing: border-box;
}
</style>