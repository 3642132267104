<template >
    <nav>
        <router-link :class="{ 'color-link' :  $route.path.includes(link.keyword)  }" v-for="link,index in links" :key="index" :to="link.link" >{{link.text}}</router-link>
    </nav>
</template>
<script>
export default {
    data(){
        return {
            links : [
                {
                    keyword : 'terms',
                    link : '/contact/terms-conditions',
                    text : "Terms and Conditions"
                },
                {
                    keyword : 'privacy',
                    link : "/contact/privacy-policy",
                    text : "Privacy Policy"
                }
            ]
        }
    }
}
</script>
<style scoped>
    nav{
        display: flex;
        justify-content: flex-start;
        align-items: center;

        padding: 0% 8%;

        height: 8vh;
        min-height: 50px;
        width: 100%;

        gap: 5px;
        box-sizing: border-box;
    }
    nav .color-link{
        background-color: var(--dark-blue);
        color: white;
        border-radius: 5px;
    }
    nav a {
        padding: 3px;
        box-sizing: border-box;
        text-decoration: none;
        color: black;
    }
</style>