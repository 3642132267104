<template lang="">
    <indexLayout>
        <template #content>
            <div class="holder">
        <div class="matchsquare" >
            <p style="font-size:26px;font-weight:500;" >Calling you soon📞 </p>
            <p  >
                {{matchMessage}}
            </p>
            <p style="font-weight:500;" >
                We will notify you shortly, and one of our team members will give you a call to discuss any further information or clarifications needed. Thank you for your patience.
            </p>
        </div>
       
    </div>
        </template>
    </indexLayout>
    
</template>
<script>
import indexLayout from './index/index-layout.vue';
import { AuthTokenDelete } from '@/api.service';
export default {
    name : "match-view",
    data(){
        return{
            matchMessage : "Thank you for your responses. Based on the information provided, we need to review some details to ensure everything is accurate and complete. "
        }
    },
    components : {
        indexLayout
    },
    beforeUnmount() {
        AuthTokenDelete()
    },
    beforeMount(){
        // if(!("AuthToken" in window.localStorage)){
        //     this.$router.replace({name:'index-not-found'})
        // }
    }
}
</script>
<style >
    .matchsquare{
        background-color: #f8f9fa;
        border: solid 1px #dee2e6;
        height: auto;
        width: 90vw;

        padding: 4%;
        box-sizing: border-box;

        border-radius: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6vh;
        
    }
</style>