<template >
    <div class="card">
            <slot name="icon" ></slot>
        <h2>{{ titel }}</h2>
        <p>{{ content }}</p>
    </div>
</template>
<script>
export default {
    props : ['titel',"content"]
}
</script>
<style scoped>
    .card{
        width: 100%;
        height: auto;
        min-height: 300px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        background-color: var(--light-gray ); 
        border-radius: 5px;
        gap: 20px;

        padding: 2%;
        box-sizing: border-box;
    }
    .card h2{
        color: var(--dark-blue);
    }
    @media screen and (max-width: 750px){
        .card{
            min-height: fit-content;
        }
    }

    
   
</style>