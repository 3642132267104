<template>
    <indexLayout>
        <template #content>
            <fileinput :titel="questions[indexQuestions].question">
                <template #fileInput>
                    <div>
                        <label for="file" class="labelFile"><span><svg xml:space="preserve" viewBox="0 0 184.69 184.69"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"
                                    id="Capa_1" version="1.1" width="60px" height="60px">
                                    <g>
                                        <g>
                                            <g>
                                                <path
                                                    d="M149.968,50.186c-8.017-14.308-23.796-22.515-40.717-19.813
                                C102.609,16.43,88.713,7.576,73.087,7.576c-22.117,0-40.112,17.994-40.112,40.115c0,0.913,0.036,1.854,0.118,2.834
                                C14.004,54.875,0,72.11,0,91.959c0,23.456,19.082,42.535,42.538,42.535h33.623v-7.025H42.538
                                c-19.583,0-35.509-15.929-35.509-35.509c0-17.526,13.084-32.621,30.442-35.105c0.931-0.132,1.768-0.633,2.326-1.392
                                c0.555-0.755,0.795-1.704,0.644-2.63c-0.297-1.904-0.447-3.582-0.447-5.139c0-18.249,14.852-33.094,33.094-33.094
                                c13.703,0,25.789,8.26,30.803,21.04c0.63,1.621,2.351,2.534,4.058,2.14c15.425-3.568,29.919,3.883,36.604,17.168
                                c0.508,1.027,1.503,1.736,2.641,1.897c17.368,2.473,30.481,17.569,30.481,35.112c0,19.58-15.937,35.509-35.52,35.509H97.391
                                v7.025h44.761c23.459,0,42.538-19.079,42.538-42.535C184.69,71.545,169.884,53.901,149.968,50.186z"
                                                    style="fill:#010002;"></path>
                                            </g>
                                            <g>
                                                <path d="M108.586,90.201c1.406-1.403,1.406-3.672,0-5.075L88.541,65.078
                                c-0.701-0.698-1.614-1.045-2.534-1.045l-0.064,0.011c-0.018,0-0.036-0.011-0.054-0.011c-0.931,0-1.85,0.361-2.534,1.045
                                L63.31,85.127c-1.403,1.403-1.403,3.672,0,5.075c1.403,1.406,3.672,1.406,5.075,0L82.296,76.29v97.227
                                c0,1.99,1.603,3.597,3.593,3.597c1.979,0,3.59-1.607,3.59-3.597V76.165l14.033,14.036
                                C104.91,91.608,107.183,91.608,108.586,90.201z" style="fill:#010002;">
                                                </path>
                                            </g>
                                        </g>
                                    </g>
                                </svg></span>
                            <p>drag and drop your file here or click to select a file!</p>
                        </label>
                        <input class="input" name="text" id="file" type="file" @change='saveDoc'
                            accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pdf" />
                    </div>
                </template>
                <template #buttons>
                    <primaryButton TextContent="Next" :positive="true" @click="answered" />
                </template>
            </fileinput>
        </template>
    </indexLayout>

</template>
<script>
import { useInfoStore } from '@/store';
import primaryButton from '@/components/primary-button.vue';
import fileinput from '@/components/fileinput.vue';
import { UploadDocuments, getStatusForUpload } from '@/api.service';
import indexLayout from './index/index-layout.vue';
export default {
    name: "docuemnts-inputs",
    data() {
        return {
            infoStore: useInfoStore(),
            indexQuestions: 0,
            questions: [
                {
                    question: 'Please provide us a picture of your SSC(social security card).',
                    filled: false,
                    file: null
                },
                {
                    question: 'Please provide us a document that proves you were formerly associated with that company.',
                    filled: false,
                    file: null
                },
                {
                    question: 'Please provide us your US ID card.',
                    filled: false,
                    file: null
                },
            ],
        }
    },
    components: {
        fileinput,
        primaryButton,
        indexLayout
    },
    methods: {
        async answered() {
            if (this.indexQuestions == 0 || this.indexQuestions == 1) {
                if (this.questions[this.indexQuestions].file != null) {
                    this.indexQuestions += 1;
                } else {
                    this.emitter.emit("sidebar-error", {
                        error: true,
                        description: "Please upload a document."
                    })
                }
            } else {

                if (this.questions[this.indexQuestions].file != null) {
                    try {
                        await UploadDocuments(this.questions[0].file, this.questions[1].file,this.questions[2].file)

                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    this.emitter.emit("sidebar-error", {
                        error: true,
                        description: "Please upload a document."
                    })
                }
            }
        },
        saveDoc(event) {
            // const file = event.target.files[0];
            this.questions[this.indexQuestions].file = event.target.files[0];
            console.log("DOCMENT UPLOADED", this.questions[this.indexQuestions].file)
        },

    },
    mounted() {
        console.log("AuthToken" in window.localStorage)
        if(!("AuthToken" in window.localStorage)){
            this.$router.replace({name:'index-not-found'})
            return;
        }
        getStatusForUpload()
    }
}
</script>
<style scoped>
.input {
    max-width: 190px;
    display: none;
}

.labelFile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250px;
    height: 190px;
    border: 2px dashed #ccc;
    align-items: center;
    text-align: center;
    padding: 5px;
    color: #404040;
    cursor: pointer;
}
</style>