// const BASE_URL = "https://jidsu.pythonanywhere.com/api/"
const BASE_URL = process.env.VUE_APP_BASE_URL
// const BASE_URL = "http://instaswiss.us/api/"


// const BASE_URL = process.env.VUE_APP_BASE_URL;
/* --------- console.log("process.production.env.VUE_APP_BASE_URL",) -------- */
 
import toolKit from "./main"
import { useInfoStore } from "./store";

export function backofficeCustomerDocumentRetrieve(id, document_type) {
    const Token = getToken(); // Make sure this function correctly retrieves the token
    return fetch(`${BASE_URL}customers/${id}/documents/${document_type}/`, {
      method: 'GET',
      headers: {
        Authorization: `Token ${Token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          // Handle non-OK responses
          return response.text().then((text) => {
            throw new Error(text || 'Failed to retrieve document.');
          });
        }
        // Return the response as a blob for binary data
        return response.blob();
      })
      .catch((error) => {
        console.error('Error in fetch:', error);
        throw error; // Rethrow the error for handling in the Vue component
      });
} 

export  function getToken(){
    const store = useInfoStore();
    const tokenLocalSto = window.localStorage.getItem("AuthToken")
    if(tokenLocalSto == null){
        toolKit.router.replace({name:"backoffice-login"})
        return;
    }
    store.Token = tokenLocalSto
    return tokenLocalSto;

}
export  function AuthTokenDelete(){
    const store = useInfoStore();
    window.localStorage.removeItem("AuthToken")
    store.Token = null
}
 function setToken(token){
    window.localStorage.setItem("AuthToken",token)
    const store = useInfoStore();
    console.log("Setting token here")
    store.Token = token;
}
export function logoutBackoffice(){
    return new Promise((resolve,reject)=>{
        fetch(BASE_URL+'backoffice/logout/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            console.log(response.status)
            if (!response.ok) {
                throw new Error('Something went wrong please try again later.');
            } 
            response.json()
            .then(() => {
                AuthTokenDelete();
                toolKit.router.replace({name:"backoffice-login"})
            })
        })
        
        .catch(error => {
            console.error('Error:', error);
            reject(error)
        });
    })
}
export function getAnswers(Token){
    setToken(Token)
    return new Promise((resolve,reject)=>{
        fetch(BASE_URL+'customers/answers/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization : "Token "+Token
            },
        })
        .then(response => {
            console.log(response.status)
            if(response.status == 403){
                toolKit.router.replace({name : "wait"})
                return;
            }
            if(response.status == 204){
                toolKit.router.replace({name : "fill_document"})
                toolKit.emitter.emit("sidebar-error",{
                    error : false,
                    description : "Upload your documents."
                })
                return;
            }
            if(response.status == 206){
                toolKit.router.replace({name : "fix_document"})
                toolKit.emitter.emit("sidebar-error",{
                    error : false,
                    description : "Upload your documents."
                })
                return;
            }
            if(response.status == 401){
                toolKit.router.replace({name : 'index-not-found'})
                return 
            }
            if (!response.ok) {
                throw new Error('Network response was not ok');
            } 
            return response.json();
        })
        .then(data => {
            return resolve(data);
    
        })
        .catch(error => {
            console.error('Error:', error);
            reject(error)
        });
    })
    
}

export function UploadDocuments(ssc_file,company_file,id_card){
    const formData = new FormData();
    formData.append('ssc', ssc_file);
    formData.append('company_document', company_file);
    formData.append('id_card', id_card);
    return new Promise((resolve,reject)=>{
        const Token = getToken();
        
        fetch(BASE_URL+'customers/documents/type/upload/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                // 'Content-Type': 'multipart/form-data',
                Authorization : "Token "+Token
            },
            body : formData
        })
        .then(response => {
            console.log(response.status)
            if(response.status == 226){
                console.log("already uploaded.")
                return;
            }
            if(response.status == 201){
                toolKit.router.replace({name : "callyou"})
                return;
            }
            if (!response.ok) {
                throw new Error('Network response was not ok');
            } 
            return response.json();
        })
        .then(data => {
            console.log('SuccessHERE', data);
            resolve(data);
    
        })
        .catch(error => {
            console.error('Error:', error);
            reject(error)
        });
    })
    
    
    
}

export function getStatusForUpload(Token){
    // http://127.0.0.1:8000/api/customers/documents/
    fetch(BASE_URL+'customers/documents/', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization : "Token "+Token
        },
       
    })
    .then(response => {
        console.log(response.status)
        if(response.status == 226){
            toolKit.router.replace({name : "callyou"})
            return;
        }
        if (!response.ok) {
            throw new Error('Network response was not ok');
        } 
        return response.json();
    })
    .then(data => {
        console.log('SuccessHERE', data);
        return data;

    })
    .catch(error => {
        console.error('Error:', error);
    });
}

export async function  login(username,password){
    console.log("BASE_URL",BASE_URL)
    return new Promise((resolve,reject)=>{
        fetch(BASE_URL+'backoffice/login/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify({
                "password": password,
                "username": username
            })
        })
        .then(response => {
            console.log(response.status)
            if(response.status == 400){
                toolKit.emitter.emit("sidebar-error",{
                    error : true,
                    description : "invalid creds"
                })
                throw ("invalid creds.")
            }
            if (!response.ok) {
                throw new Error('Something went wrong please try again later.');
            } 
            response.json()
            .then(data => {
                // const store = useInfoStore();
                setToken(data.key)
                // store.Token = data.key
                resolve(data);
            })
        })
        
        .catch(error => {
            console.error('Error:', error);
            reject(error)
        });
    })
    
   
}

export async function getCustomers(query){
    let tail ="";
    if(query == null){
        query = "";
    }else{
        tail = "?phone="+query.replace("+","")
    }
    return new Promise((resolve,reject)=>{
        const token = getToken()
        // const store = useInfoStore();
        fetch(BASE_URL+'customers'+tail, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization : "Token "+token
            },
           
        })
        .then((response) =>  {
            if(response.status == 401 || response.status == 403){
                AuthTokenDelete()
                toolKit.router.replace({name:"backoffice-login"})
            }
            if (!response.ok) {
                throw new Error('Network response was not ok');
            } 
            response.json()
            .then((data)=>{
                return resolve(data);
            })
        })
        .catch(error => {
            reject(error)
        });
    })
    
}

export async function getCustomerByID(ID){
    const token = getToken()
    return new Promise((resolve,reject)=>{
        // const store = useInfoStore();
        fetch(BASE_URL+'customers/'+ID+"/", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization : "Token "+token
            },
           
        })
        .then((response) =>  {
            console.log(response.status)
            if(response.status == 401 || response.status == 403){
                AuthTokenDelete()
            }
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            } 
            response.json()
            .then((data)=>{
                return resolve(data);
            })
        })
        .catch(error => {
            reject(error)
        });
    })
    
}

export async function  createCustomer(payload){
    return new Promise((resolve,reject)=>{
        const token = getToken()
        fetch(BASE_URL+'customers/create/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization : "Token "+token
            },
            body : JSON.stringify(payload)
        })
        .then(response => {
            console.log(response.status)
            if(response.status == 401 || response.status == 403 ){
                toolKit.router.replace({name:"backoffice-login"})
                toolKit.emitter.emit("sidebar-error",{
                    error : true,
                    description : "Please login."
                })
                return 
            }
            
            if (!response.ok) {
                throw new Error('Something went wrong please try again later.');
            } 
            response.json()
            .then(data => {
                toolKit.emitter.emit("sidebar-error",{
                    error : false,
                    description : "Added successfully"
                })
                return resolve(data);
            })
        })
        
        .catch(error => {
            console.error('Error:', error);
            reject(error)
        });
    })
    
    
}

export async function  sendMessage(payload){
    return new Promise((resolve,reject)=>{
        const token = getToken()
        fetch(BASE_URL+'customers/messages/send/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization : "Token "+token
            },
            body : JSON.stringify(payload)
        })
        .then(response => {
            console.log(response.status)
            if(response.status == 401){
                toolKit.router.replace({name:"backoffice-login"})
                toolKit.emitter.emit("sidebar-error",{
                    error : true,
                    description : "Please login."
                })
                return 
            }
            if(response.status == 403){
                throw ("Not authorized.")
            }
            if(response.status == 226){
                toolKit.emitter.emit("sidebar-error",{
                    error : true,
                    description : "User Already answered the questions."
                })
                return;
            }
            if (!response.ok) {
                toolKit.emitter.emit("sidebar-error",{
                    error : true,
                    description : "An error has occured please contact your developper"
                })
                throw new Error('Something went wrong please try again later.');
            } 
            response.json()
            .then(data => {
                toolKit.emitter.emit("sidebar-error",{
                    error : false,
                    description : "Sent successfully"
                })
                return resolve(data);
            })
        })
        
        .catch(error => {
            console.error('Error:', error);
            reject(error)
        });
    })
    
    
}

export async function informBackEndForAnswers(payload,token){
    console.log("payload",payload)
    return new Promise((resolve,reject)=>{
            fetch(BASE_URL+'customers/check/answers/', {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization : "Token "+token
                },
                body : JSON.stringify(payload)
            })
            .then(response => {
                console.log(response.status)
                if(response.status == 401){
                    toolKit.router.replace({name:"backoffice-login"})
                    return 
                }
                if(response.status == 403){
                    throw ("Not authorized.")
                }
                if (!response.ok) {
                    throw new Error('Something went wrong please try again later.');
                } 
                response.json()
                .then(data => {

                    return resolve(data);
                })
            })
            
            .catch(error => {
                console.error('Error:', error);
                reject(error)
            });
    })  
}

export async function  customerDelete(id){
    return new Promise((resolve,reject)=>{
        const token = getToken()
        fetch(BASE_URL+'customers/'+id+'/delete/', {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization : "Token "+token
            },
        })
        .then(response => {
            console.log(response.status)
            if(response.status == 401){
                toolKit.router.replace({name:"backoffice-login"})
                toolKit.emitter.emit("sidebar-error",{
                    error : true,
                    description : "Please login."
                })
                return 
            }
            if(response.status == 403){
                throw ("Not authorized.")
            }
            if(response.status == 204){
                toolKit.emitter.emit("sidebar-error",{
                    error : false,
                    description : "Deleted successfully"
                })
                return resolve();
            }
            if (!response.ok) {
                toolKit.emitter.emit("sidebar-error",{
                    error : true,
                    description : "Something went wrong please try again later."
                })
                throw new Error('Something went wrong please try again later.');
            } 
            
        })
        
        .catch(error => {
            console.error('Error:', error);
            reject(error)
        });
    })
    
    
}

export async function getInTouch(payload){
    return new Promise((resolve,reject)=>{
        fetch(BASE_URL+'customers/contact/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(payload)
        })
        .then(async (response) =>{
            console.log(response.status)
            if(response.status == 400){
                
                await response.json()
                .then((data) => {
                    if ("phone_number" in data.customer){
                        toolKit.emitter.emit("sidebar-error",{
                            error : true,
                            description : "Invalid phone number or already used."
                        })
                    }else{
                        toolKit.emitter.emit("sidebar-error",{
                            error : true,
                            description : "Please verify your informations."
                        })
                    }
                    return reject({
                        status : response.status,
                        data : data
                    });
                }) 
            }
            if (!response.ok) {
                toolKit.emitter.emit("sidebar-error",{
                    error : true,
                    description : "An error has occured please try again later"
                })
                throw new Error('Something went wrong please try again later.');
            } 
            response.json()
            .then(data => {
                toolKit.router.replace({name:"callyou"})
                toolKit.emitter.emit("sidebar-error",{
                    error : false,
                    description : "Sent successfully"
                })
                return resolve(data);
            })
        })
        
        .catch(error => {
            console.error('Error:', error);
            reject(error)
        });
    })
}

export async function DocumentCheck(payload){
    return new Promise((resolve,reject)=>{
        const token = getToken()
        fetch(BASE_URL+'customers/documents/validate/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization : "Token "+token
            },
            body : JSON.stringify(payload)
        })
        .then(async (response) =>{
            console.log(response.status)
            if(response.status == 400){
                await response.json()
                .then((data) => {
                    toolKit.emitter.emit("sidebar-error",{
                        error : true,
                        description : data
                    })
                    resolve()
                }) 
            }else if(response.status == 401){
                toolKit.router.replace("/home/")
                AuthTokenDelete()
            }
            if (!response.ok) {
                toolKit.emitter.emit("sidebar-error",{
                    error : true,
                    description : "An error has occured please try again later"
                })
                throw new Error('Something went wrong please try again later.');
            } 
            const data = response.json();
            await toolKit.router.go()
            toolKit.emitter.emit("sidebar-error",{
                error : false,
                description : "Status changed successfully"
            })
            return resolve(data);
        })
        
        
        .catch(error => {
            console.error('Error:', error);
            reject(error)
        });
    })
}

export async function getRefusal(){
    const token = getToken()
    return new Promise((resolve,reject)=>{
        // const store = useInfoStore();
        fetch(BASE_URL+"customers/documents/refusal/", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization : "Token "+token
            },
           
        })
        .then((response) =>  {
            console.log(response.status)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            } 
            return response.json()
            
        })
        .then((data)=>{
            return resolve(data);
        })
        .catch(error => {
            reject(error)
        });
    })
}

export function CustomerDocumentRetrieve( document_type) {
    const Token = getToken(); // Make sure this function correctly retrieves the token
    return fetch(`${BASE_URL}customers/documents/type/${document_type}/`, { 
      method: 'GET',
      headers: {
        Authorization: `Token ${Token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          // Handle non-OK responses
          return response.text().then((text) => {
            throw new Error(text || 'Failed to retrieve document.');
          });
        }
        // Return the response as a blob for binary data
        return response.blob();
      })
      .catch((error) => {
        console.error('Error in fetch:', error);
        throw error; // Rethrow the error for handling in the Vue component
      });
} 


export function UpdateDocuments(files){
    console.log("files upload",files)
    const extend_url = `${BASE_URL}customers/documents/type/update/` ;
    const formData = new FormData();
    for(let file of files){
        formData.append(file.type, file.file);
    }
    return new Promise((resolve,reject)=>{
        const Token = getToken();
        fetch(extend_url, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                Authorization : "Token "+Token
            },
            body : formData
        })
        .then(response => {
            console.log(response.status)
            if (!response.ok) {
                toolKit.emitter.emit("sidebar-error",{
                    error : true,
                    description : "An error has occured pleas try again later."
                })
                throw new Error('Network response was not ok');
            } 
            toolKit.emitter.emit("sidebar-error",{
                error : false,
                description : "Uploaded successfully."
            })
            resolve()
        })
    
        .catch(error => {
            console.error('Error:', error);
            reject(error)
        });
    })
    
    
    
}

