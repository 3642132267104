<template>
    <div class="backoffice-base-holder" >
            <backofficeLayout :hideNav="false">

                <template #content >
                    <div class="list-holder">
                        
                        <sectionHeader TitelSection="Customers" >
                            <template #extra >
                                <router-link class="link_customer_add" :to="'/backoffice/customers/create'">+Add customer</router-link>
                                <div class="search">
                                    <input v-model="query" @change="searchCustomer(query)" type="text" class="search__input" placeholder="phone number">
                                    <button class="search__button">
                                        <svg class="search__icon" aria-hidden="true" viewBox="0 0 24 24">
                                            <g>
                                                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </template>
                        </sectionHeader>
                        <div v-if="items.length != 0" class="table-container">
                            <table>
                            <thead style="background-color: red;">
                                <tr>
                                    <th>Phone Number</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Town</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in items" :key="item.id" :class="{ odd: index % 2 !== 0 }" @click="routeEditCustomer(item.id)">
                                <td>{{ item.phone_number }}</td>
                                <td>{{ item.first_name }}</td>
                                <td>{{ item.last_name }}</td>
                                <td>{{ item.town }}</td>
                                <td>{{ item.match }}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div class="display-not-list" v-else >
                            <p style=" font-size: 18px;color:gray;font-weight: 300;text-align: left;margin-bottom: 50px;" >No messages have been sent yet.</p>
                        </div>
                    </div>
                </template>
            </backofficeLayout>
    </div>
</template>
<script>
import backofficeLayout from '.././backoffice-layout.vue';
import { useInfoStore } from '@/store';
import { getCustomers,getToken } from '@/api.service';
import sectionHeader from '@/components/section-header.vue';
export default {
    name:"login-view",
    components: {
        backofficeLayout,
        sectionHeader,
    },
    data(){
        return{
           infoStore : useInfoStore(),
           items: [],
           query : "",
        }
    },
    methods : {
        routeEditCustomer(customerID){
            this.$router.push("/backoffice/customers/"+customerID)
        },
        async searchCustomer(){
            console.log("query",this.query)
            if (getToken() == null) {
                this.$router.replace("/backoffice/login")
            }
            try {
                await getCustomers(this.query) // Waits for getCustomers to resolve
                    .then(customers => {
                        this.items = customers;
                        console.log("customers ds", customers) ;
                    })
            } catch (error) {
                console.error("Error fetching customers:", error);
            }
        }
    },
    async beforeMount(){
        if(getToken() == null){''
            this.$router.replace("/backoffice/login")
        }
        try {
            await getCustomers() // Waits for getCustomers to resolve
            .then(customers=>{
                this.items = customers;
                // console.log("customers ds", customers) ;
            })
        } catch (error) { 
            console.error("Error fetching customers:", error);
        }
        
    },

}
</script>
<style scoped>
.display-not-list{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8%;
  box-sizing: border-box;
}
.link_customer_add{

    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: #8ecae6;
}

.list-holder{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    height: 100%;

    min-height: 100vh;


}

.list-holder .list-header{
    height: 8%;
    width: 100%;

    background-color: #023047;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    padding: 2%;
    box-sizing: border-box;
}
.list-holder .list-header p{
    font-size: 20px;
    color: #8ecae6;
}
.table-container {
  width: 100%;
  margin:  0;

}


table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px 15px;
  box-sizing: border-box;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tbody tr {
  background-color: #f9f9f9;
}

tbody tr.odd {
  background-color: #f1f1f1;
}

tbody tr:hover {
  background-color: #e2e2e2;
}

td {
  border: none;
}
</style>
<style scoped>
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.search__input {
  font-family: inherit;
  font-size: inherit;
  background-color: #f4f2f2;
  border: none;
  color: #646464;
  padding: 0.7rem 1rem;
  border-radius: 30px;
  width: 12em;
  transition: all ease-in-out .5s;
  margin-right: -2rem;
}

.search__input:hover, .search__input:focus {
  box-shadow: 0 0 1em #00000013;
}

.search__input:focus {
  outline: none;
  background-color: #f0eeee;
}

.search__input::-webkit-input-placeholder {
  font-weight: 100;
  color: #ccc;
}

.search__input:focus + .search__button {
  background-color: #f0eeee;
}

.search__button {
  border: none;
  background-color: #f4f2f2;
  margin-top: .1em;
}

.search__button:hover {
  cursor: pointer;
}

.search__icon {
  height: 1.3em;
  width: 1.3em;
  fill: #b4b4b4;
}
</style>