<template>
    <div class=" backoffice-holder-login" >
            <backofficeLayout :hideNav="true">

                <template #content >
                    <form   @submit.prevent="login_func" class="backoffice-form">
                        <p class="form-title">Sign in to your account</p>
                            <div class="input-container">
                                <input v-model="username" type="text" placeholder="Enter username">
                            </div>
                            <div class="input-container">
                                <input v-model="password"  type="password" placeholder="Enter password">
                            </div>
                        <actionButton  text="Sign in" />
                    </form>
                </template>
            </backofficeLayout>
    </div>
</template>
<script>
import actionButton from '@/components/action-button.vue';
import backofficeLayout from '.././backoffice-layout.vue';
import { useInfoStore } from '@/store';
import { login,getToken } from '@/api.service';
export default {
    name:"login-view",
    components: {
        backofficeLayout,
        actionButton
    },
    data(){
        return{
           username : null,
           password : null,
           infoStore : useInfoStore(),
        }
    },
    methods : {
        async login_func(){
            try{
                if(this.username == null || this.password == null){
                throw ("invalid creds.")
                }
                await login(this.username,this.password)
                .then(()=>{
                    this.$router.replace("/backoffice/customers")
                })
            }catch(err){
                console.log(err)
            }
        }
    },
    beforeMount(){
        if(getToken() != null){
            this.$router.replace({name:"backoffice-customers"})
        }
    }
}
</script>
<style scoped>

.backoffice-holder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100vh;
    width: 100vw;
}
.backoffice-holder-login{
    
    height: 100%;
    width: 100%;
    min-height: 600px;
    
    box-sizing: border-box;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    background-color: #e9ecef;

}
.backoffice-form {
  background-color: #fff;
  display: block;
  padding: 1rem;
  max-width: 350px;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.form-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
}

.input-container {
  position: relative;
}

.input-container input, .form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px 0;
}

.input-container input {
  background-color: #fff;
  padding: 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 300px;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.submit {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: #4F46E5;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  width: 100%;
  border-radius: 0.5rem;
  text-transform: uppercase;
}

.signup-link {
  color: #6B7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}

.signup-link a {
  text-decoration: underline;
}


</style>