<template>
    <div  v-if="vueMe" :class="{'error' : error , 'ok' : !error }" class="square">
        <div class="header" >
            <svg v-if="error==false" width="18" height="18" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_101_4)">
                    <path
                        d="M18 2C14.8355 2 11.7421 2.93838 9.11088 4.69649C6.4797 6.45459 4.42894 8.95345 3.21793 11.8771C2.00693 14.8007 1.69008 18.0177 2.30744 21.1214C2.92481 24.2251 4.44866 27.0761 6.6863 29.3137C8.92394 31.5513 11.7749 33.0752 14.8786 33.6926C17.9823 34.3099 21.1993 33.9931 24.1229 32.7821C27.0466 31.5711 29.5454 29.5203 31.3035 26.8891C33.0616 24.2579 34 21.1645 34 18C34 13.7565 32.3143 9.68687 29.3137 6.68629C26.3131 3.68571 22.2435 2 18 2ZM18 32C15.2311 32 12.5243 31.1789 10.222 29.6406C7.91974 28.1022 6.12532 25.9157 5.06569 23.3576C4.00607 20.7994 3.72882 17.9845 4.26901 15.2687C4.80921 12.553 6.14258 10.0584 8.10051 8.1005C10.0584 6.14257 12.553 4.8092 15.2687 4.26901C17.9845 3.72881 20.7994 4.00606 23.3576 5.06569C25.9157 6.12531 28.1022 7.91973 29.6406 10.222C31.1789 12.5243 32 15.2311 32 18C32 21.713 30.525 25.274 27.8995 27.8995C25.274 30.525 21.713 32 18 32Z"
                        fill="#4ECB71" />
                    <path
                        d="M28 12.1C27.8126 11.9137 27.5592 11.8092 27.295 11.8092C27.0308 11.8092 26.7774 11.9137 26.59 12.1L15.49 23.15L9.49 17.15C9.307 16.9524 9.053 16.8356 8.78389 16.8253C8.51477 16.815 8.25258 16.912 8.055 17.095C7.85741 17.278 7.74061 17.532 7.7303 17.8011C7.71998 18.0702 7.817 18.3324 8 18.53L15.49 26L28 13.52C28.0937 13.427 28.1681 13.3164 28.2189 13.1946C28.2697 13.0727 28.2958 12.942 28.2958 12.81C28.2958 12.678 28.2697 12.5473 28.2189 12.4254C28.1681 12.3036 28.0937 12.193 28 12.1Z"
                        fill="#4ECB71" />
                </g>
                <defs>
                    <clipPath id="clip0_101_4">
                        <rect width="36" height="36" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <svg v-else-if="error== true" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM12 2C6.47 2 2 6.5 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#F24E1E"/>
            </svg>

            <svg v-else-if="warning == true && error == null " width="18" height="18" viewBox="0 0 32 32" fill="none" >
                <path d="M19.064 3.80903C17.732 1.39903 14.268 1.39903 12.936 3.80903L2.44302 22.808C1.15502 25.14 2.84302 28 5.50702 28H26.494C29.158 28 30.846 25.14 29.557 22.808L19.064 3.80903ZM17.25 22C17.25 22.3315 17.1183 22.6495 16.8839 22.8839C16.6495 23.1183 16.3315 23.25 16 23.25C15.6685 23.25 15.3506 23.1183 15.1161 22.8839C14.8817 22.6495 14.75 22.3315 14.75 22C14.75 21.6685 14.8817 21.3506 15.1161 21.1161C15.3506 20.8817 15.6685 20.75 16 20.75C16.3315 20.75 16.6495 20.8817 16.8839 21.1161C17.1183 21.3506 17.25 21.6685 17.25 22ZM16 9.00003C16.2652 9.00003 16.5196 9.10538 16.7071 9.29292C16.8947 9.48046 17 9.73481 17 10V18C17 18.2652 16.8947 18.5196 16.7071 18.7071C16.5196 18.8947 16.2652 19 16 19C15.7348 19 15.4805 18.8947 15.2929 18.7071C15.1054 18.5196 15 18.2652 15 18V10C15 9.73481 15.1054 9.48046 15.2929 9.29292C15.4805 9.10538 15.7348 9.00003 16 9.00003Z" fill="#FFD233"/>
            </svg>


            <p style="width: 100%;" >
                <strong>
                    <span v-if="error==false" >Success</span>
                    <span v-else-if="error==true" >Failed</span>
                    <span v-else-if="warning==true" >Warning</span>
                </strong>
            </p>
            <svg @click="hideMe" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.3 5.71001C18.2075 5.61731 18.0976 5.54376 17.9766 5.49358C17.8557 5.4434 17.726 5.41757 17.595 5.41757C17.464 5.41757 17.3344 5.4434 17.2134 5.49358C17.0924 5.54376 16.9825 5.61731 16.89 5.71001L12 10.59L7.11001 5.70001C7.01743 5.60743 6.90752 5.53399 6.78655 5.48388C6.66559 5.43378 6.53594 5.40799 6.40501 5.40799C6.27408 5.40799 6.14443 5.43378 6.02347 5.48388C5.9025 5.53399 5.79259 5.60743 5.70001 5.70001C5.60743 5.79259 5.53399 5.9025 5.48388 6.02347C5.43378 6.14443 5.40799 6.27408 5.40799 6.40501C5.40799 6.53594 5.43378 6.66559 5.48388 6.78655C5.53399 6.90752 5.60743 7.01743 5.70001 7.11001L10.59 12L5.70001 16.89C5.60743 16.9826 5.53399 17.0925 5.48388 17.2135C5.43378 17.3344 5.40799 17.4641 5.40799 17.595C5.40799 17.7259 5.43378 17.8556 5.48388 17.9766C5.53399 18.0975 5.60743 18.2074 5.70001 18.3C5.79259 18.3926 5.9025 18.466 6.02347 18.5161C6.14443 18.5662 6.27408 18.592 6.40501 18.592C6.53594 18.592 6.66559 18.5662 6.78655 18.5161C6.90752 18.466 7.01743 18.3926 7.11001 18.3L12 13.41L16.89 18.3C16.9826 18.3926 17.0925 18.466 17.2135 18.5161C17.3344 18.5662 17.4641 18.592 17.595 18.592C17.7259 18.592 17.8556 18.5662 17.9766 18.5161C18.0975 18.466 18.2074 18.3926 18.3 18.3C18.3926 18.2074 18.466 18.0975 18.5161 17.9766C18.5662 17.8556 18.592 17.7259 18.592 17.595C18.592 17.4641 18.5662 17.3344 18.5161 17.2135C18.466 17.0925 18.3926 16.9826 18.3 16.89L13.41 12L18.3 7.11001C18.68 6.73001 18.68 6.09001 18.3 5.71001Z"
                    fill="#222222" />
            </svg>
            
        </div>

        <p class="description">
            <slot name="description">

            </slot>
        </p>
    </div>
</template>
<script>
export default{
   props : {
    error : Boolean,
    warning : Boolean
   },
   data(){
    return {
        vueMe : true
    }
   },
    mounted(){
        setTimeout(()=>{
            this.vueMe = false;
        },3000);
    }
}
</script>
<style>
.description{
    color: var(--gray-text-color-1);
    font-size: 14px;
}

.square{
    background-color: white;

    border-radius: 5px;
    
    padding: 3px;
    box-sizing: border-box;

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.square .header{
    background-color: white;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:5px;

    width: 100%;
}
.square .header p {
    font-weight: 500;
    font-size: 14px;
}

.header i {
    justify-self: flex-end;
}
</style>