<template >
    <legalLayout>
        <template #content >
                <VueMarkdown class="mark-down" :source="src" ></VueMarkdown>
        </template>
    </legalLayout>
</template>
<script>
import VueMarkdown from 'vue-markdown-render';
import legalLayout from '../legal-layout.vue';
export default {
    components : {
        legalLayout,
        VueMarkdown
    },
    data(){
        return{
            src : `

# Privacy Policy for Recovery Services

This Privacy Policy explains how **Instaswiss LLC** ("we", "us", or "our") collects, uses, and discloses information from clients ("you" or "your") in connection with our recovery services.

## Table of Contents
1. [Information We Collect](#information-we-collect)
2. [How We Use Your Information](#how-we-use-your-information)
3. [Sharing Your Information](#sharing-your-information)
4. [Data Security](#data-security)
5. [Your Rights](#your-rights)
6. [Changes to This Privacy Policy](#changes-to-this-privacy-policy)
7. [Contact Us](#contact-us)

## 1. Information We Collect
We collect information that you provide directly to us, such as your name, address, contact details, and any other information necessary for the recovery of funds and surpluses owed to you. We may also collect information from third parties as required to perform our services.

## 2. How We Use Your Information
We use your information to:
- Assist in the recovery of funds and surpluses owed to you.
- Communicate with you regarding your claims and our services.
- Comply with legal and regulatory requirements.
- Improve our services and customer experience.

## 3. Sharing Your Information
We do not sell or rent your personal information to third parties. We may share your information with:
- Service providers and partners who assist us in providing our services.
- Legal and regulatory authorities as required by law.
- Other parties with your consent or as necessary to protect our rights and property.

## 4. Data Security
We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the internet or data storage system can be guaranteed to be 100% secure.

## 5. Your Rights
You have the right to access, correct, or delete your personal information. You may also have the right to object to or restrict certain processing of your data. To exercise your rights, please contact us using the information provided below.

## 6. Changes to This Privacy Policy
We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically for any changes.

## 7. Contact Us
If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:

Instaswiss LLC  
New York 
instaswiss@gmail.com  

`
        }
    }
}
</script>
<style >
    .mark-down {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .mark-down *{
        text-align: left;
    }
    .mark-down ol,.mark-down ul{
        padding-left: 8%;
        box-sizing: border-box;
    }
</style>