<template lang="">
        <indexLayout>
        <template #content>
            <div class="holder">
        <div class="matchsquare" >
            <p style="font-size:26px;font-weight:500;" >Sorry😕</p>
            <p>
                {{matchMessage}}
            </p>
           
        </div>
       
    </div>


        </template>
    </indexLayout>

</template>
<script>
import indexLayout from './index/index-layout.vue';
import { AuthTokenDelete } from '@/api.service';
export default {
    name : "match-view",
    data(){
        return{
            matchMessage : "After reviewing your answers, we regret to inform you that you do not meet the eligibility criteria."
        }
    },
    components : {
        indexLayout
    },
    beforeMount(){
        if(!("AuthToken" in window.localStorage)){
            this.$router.replace({name:'index-not-found'})
        }
    },
    beforeUnmount() {
        AuthTokenDelete()
    },
}
</script>
<style >
    .matchsquare{
        background-color: #f8f9fa;
        border: solid 1px #dee2e6;
        height: auto;
        width: 90vw;

        padding: 4%;
        box-sizing: border-box;

        border-radius: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6vh;
        
    }
</style>