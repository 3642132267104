<template>
    <div class="card">
        <div class="titel card-shadow">
            <h1>{{ titel }}</h1>
        </div>
        <div class="card-description card-shadow">
            <h4>{{ description }}</h4>
        </div>
        <img :src="img_source" alt="">
    </div>
</template>
<script>
export default {
    props: ["img_source", "titel", "description"],

}
</script>
<style scoped>
.card-shadow {
    -webkit-box-shadow: 0px 10px 39px 10px rgba(62, 66, 66, 0.22);
    -moz-box-shadow: 0px 10px 39px 10px rgba(62, 66, 66, 0.22);
    box-shadow: 0px 10px 39px 10px rgba(62, 66, 66, 0.22);
}

.card {
    position: relative;


    display: flex;
    justify-content: center;
    align-items: center;

    -webkit-box-shadow: 0px 10px 84px 5px rgba(255, 255, 255, 0.16);
    -moz-box-shadow: 0px 10px 84px 5px rgba(255, 255, 255, 0.16);
    box-shadow: 0px 10px 84px 5px rgba(255, 255, 255, 0.16);

    border-radius: 20px;

    min-height: 100px;
}

.card img {
    width: 100%;
    height: auto;

    border-radius: 20px;
}

.card .titel {
    position: absolute;
    width: auto;

    left: -25px;
    top: 20px;

    background-color: white;
    padding: 2%;
    box-sizing: border-box;

    font-size: 1vw;

    border-radius: 10px;

}

.card .card-description {
    position: absolute;
    width: auto;

    right: -50px;
    bottom: 20px;

    background-color: white;
    box-sizing: border-box;
    padding: 2%;

    font-size: 1.5vw;

    border-radius: 10px;
}
@media screen and (max-width: 750px){
    #works .card-description {
        font-size: 3vw;
        word-wrap: break-word;
        word-wrap: break-word; /* older syntax */
        overflow-wrap: break-word;

        border-radius: 0px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        right: 0;
        bottom: 0;
    }
    #works .card .titel {
        font-size: 3vw;
        left: -10px;
    }
    
   
}
</style>