<template>
    <indexLayout>
        <template #content>
            <section id="hero-section">
                <div class="triangles">
                    <svg width="100%" height="100%" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.4839 20.855L23.4128 39.7274L4.61143 20.9261L23.4839 20.855Z" stroke="#219EBC"
                            stroke-width="0.5" />
                        <path d="M33.4839 10.855L33.4128 29.7274L14.6114 10.9261L33.4839 10.855Z" stroke="#219EBC"
                            stroke-width="0.5" />
                    </svg>
                </div>

                <div class="text-content">
                    <h1 class="hero-section-main-titel">Get What’s Rightfully Yours with Instaswiss</h1>
                    <h5 class="hero-section-second-titel">Helping U.S. Residents Recover Unclaimed Funds and Surpluses
                        Fast</h5>
                    <actionButton @click="$router.push({name : 'index-contact'})" class="hero-section-button" InnerText="Start Your Recovery Today" />
                </div>
                <div class="image ">
                    <div class="half-circle">
                        <svg width="100%" height="100%" viewBox="0 0 100 50" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50C58.5 50 77.6142 50 50 50C22.3858 50 32 50 0 50C0 22.3858 22.3858 0 50 0C77.6142 0 100 22.3858 100 50Z"
                                fill="#219EBC" />
                        </svg>

                    </div>
                    <img class="image-happy-customer-slide" :src="happy_customer_image" alt="Happy Customer">
                </div>
            </section>
            <section id="explained">
                <div class="explained-holder explained-main-titel">
                    <div class="left">
                        <h1 >Every year,</h1>
                        <h3 >
                            millions of dollars in unclaimed funds and surpluses go unnoticed by their rightful owners.
                            At
                            Instaswiss, we specialize in helping U.S. residents recover the money that belongs to
                            them—quickly,
                            easily, and with zero hassle.
                        </h3>
                        <secondActionButton height="100%" fontSize="16px" borderRadius="10px" @click="$router.push({name : 'index-contact'})"  InnerText="Claim what's yours" />
                    </div>
                    <div class="right">
                        <svg width="50%" height="50%" viewBox="0 0 172 172" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_18_2)">
                                <path
                                    d="M145.125 126.044C145.125 158.563 118.787 158.562 86 158.562C53.2125 158.562 26.875 158.563 26.875 126.044C26.875 93.525 53.4812 40.3125 86 40.3125C118.519 40.3125 145.125 93.2563 145.125 126.044Z"
                                    fill="#DBB471" />
                                <path opacity="0.5"
                                    d="M145.125 126.044C145.125 115.025 142.169 101.588 136.794 88.6875C137.869 97.0187 135.719 111.8 110.188 117.444C73.9063 125.238 92.7188 135.45 78.475 136.794C66.3813 137.869 34.1313 133.031 27.6813 114.756C27.1438 118.788 26.6063 122.55 26.6063 126.312C26.875 158.562 53.4813 158.562 86 158.562C118.519 158.562 145.125 158.563 145.125 126.044Z"
                                    fill="#89664C" />
                                <path
                                    d="M101.856 110.456C100.781 105.081 96.2125 103.469 91.375 103.2V95.675C92.7188 96.2125 94.3313 97.0188 95.4063 97.825C97.5563 99.1688 99.7063 95.675 97.5563 94.3312C95.675 93.2562 93.525 92.1812 91.375 91.375V82.5062C91.375 79.8187 87.3438 79.8187 87.3438 82.5062V90.3C85.1938 90.0312 83.0438 90.0312 80.8938 90.3C80.625 88.4187 80.0875 86.5375 79.55 84.6562C79.0125 81.9687 74.9813 83.0437 75.7875 85.7312C76.325 87.6125 76.5938 89.4937 77.1313 91.375C76.8625 91.375 76.8625 91.375 76.5938 91.6437C71.2188 93.7937 67.725 99.1687 71.7563 104.275C73.6375 106.694 76.0563 107.5 78.7438 108.037C79.0125 111.531 79.0125 115.025 79.0125 118.519C77.1313 117.444 75.5188 115.831 74.175 114.487C72.2938 112.606 69.6063 115.562 71.2188 117.444C73.6375 120.131 76.325 122.013 79.0125 123.356C79.0125 126.313 79.2813 129.269 79.55 132.225C79.8188 134.912 83.85 134.912 83.5813 132.225C83.3125 129.537 83.0438 127.119 83.0438 124.431C84.6563 124.7 86.2688 124.7 87.8813 124.7C88.15 127.388 88.4188 130.075 88.6875 133.031C88.9563 135.719 92.9875 135.719 92.7188 133.031C92.45 130.075 92.1813 127.119 91.9125 123.894C97.825 121.744 103.2 117.175 101.856 110.456ZM87.3438 94.3312V103.2C86 103.2 84.925 103.469 83.85 103.469H82.5063L81.7 93.7938C83.5813 93.7938 85.4625 94.0625 87.3438 94.3312ZM77.6688 102.931C76.5938 102.662 75.5188 101.856 74.4438 101.05C72.025 98.6312 75.5188 96.4812 77.6688 95.4062C77.9375 98.0937 78.2063 100.513 78.475 103.2C78.2063 103.2 77.9375 103.2 77.6688 102.931ZM86.5375 120.131C85.1938 120.4 84.1188 120.131 83.0438 119.862C82.775 115.831 82.775 111.8 82.775 107.5C84.3875 107.5 86 107.231 87.6125 107.231C87.6125 111.531 87.8813 115.562 87.8813 119.862C87.3438 120.131 87.075 120.131 86.5375 120.131ZM95.4063 117.175C94.3313 117.981 92.9875 118.519 91.9125 119.056C91.6438 115.025 91.6438 111.263 91.6438 107.231H92.9875C98.0938 108.306 99.4375 113.95 95.4063 117.175Z"
                                    fill="#89664C" />
                                <path
                                    d="M91.375 147.812H85.1937V158.562L110.187 172L166.625 154.531V145.125L145.931 133.031L91.375 147.812Z"
                                    fill="#699635" />
                                <path
                                    d="M86 141.631C86 141.631 92.45 152.112 110.725 161.25C110.725 161.25 139.213 159.906 166.625 141.631C166.625 141.631 150.769 133.837 146.2 123.625C146.2 123.625 135.45 138.675 86 141.631Z"
                                    fill="#83BF4F" />
                                <path
                                    d="M73.9062 147.544L79.8187 147.275L80.625 157.219L57.2437 172L1.07499 159.906L0.268738 151.037L19.35 137.869L73.9062 147.544Z"
                                    fill="#699635" />
                                <path
                                    d="M78.475 141.362C78.475 141.362 72.8312 151.844 55.9 161.787C55.9 161.787 28.2187 162.594 0 147.812C0 147.812 15.05 139.212 18.5437 129C18.5437 129 30.3688 142.437 78.475 141.362Z"
                                    fill="#83BF4F" />
                                <path
                                    d="M65.0375 144.319C65.0375 144.319 64.2313 146.469 67.9938 146.737L58.05 155.069C58.05 155.069 52.1375 154.531 50.7938 158.562C50.7938 158.562 26.6063 155.875 11.0188 148.888C11.0188 148.888 14.2438 146.469 10.2125 144.856C10.2125 144.856 14.7813 141.631 15.8563 138.675C15.8563 138.675 20.1563 139.212 21.5 135.987C22.0375 135.719 38.1625 145.394 65.0375 144.319Z"
                                    stroke="#699635" stroke-miterlimit="10" />
                                <path
                                    d="M23.65 148.081C26.4701 148.081 28.7563 146.517 28.7563 144.587C28.7563 142.658 26.4701 141.094 23.65 141.094C20.8299 141.094 18.5438 142.658 18.5438 144.587C18.5438 146.517 20.8299 148.081 23.65 148.081Z"
                                    fill="#699635" />
                                <path
                                    d="M51.0625 140.287C51.0625 140.287 44.8813 148.35 31.4438 159.369L32.25 166.625L22.8438 164.475L22.3063 156.681C22.3063 156.681 36.0125 144.05 38.9688 137.869C38.7 137.869 43.5375 139.481 51.0625 140.287ZM113.144 138.406C113.144 138.406 115.563 144.856 133.838 156.681V164.475L144.319 161.25V153.187C144.319 153.187 131.688 144.319 124.163 135.45L113.144 138.406Z"
                                    fill="#F9F3D9" />
                                <path
                                    d="M97.825 129.806L91.9125 129.538L91.375 140.556L115.294 155.875L171.462 140.556L171.731 130.881L152.112 117.175L97.825 129.806Z"
                                    fill="#699635" />
                                <path
                                    d="M92.9875 123.087C92.9875 123.087 98.9 134.375 116.369 144.587C116.369 144.587 144.319 144.588 172 127.388C172 127.388 156.681 118.519 152.919 107.5C152.919 107.5 141.362 122.55 92.9875 123.087Z"
                                    fill="#83BF4F" />
                                <path
                                    d="M105.081 126.044C105.081 126.044 106.425 128.194 102.125 129L113.413 137.869C113.413 137.869 119.863 136.256 122.013 140.019C122.013 140.019 148.888 135.181 161.25 128.731C161.25 128.731 157.219 126.85 161.25 124.431C161.25 124.431 156.412 120.4 154.531 117.712C154.531 117.712 149.963 119.056 147.544 115.831C147.544 116.1 137.869 124.7 105.081 126.044Z"
                                    stroke="#699635" stroke-miterlimit="10" />
                                <path
                                    d="M125.775 132.763C124.7 134.106 121.206 134.644 118.25 133.838C115.294 133.031 113.95 131.15 115.025 129.806C116.1 128.463 119.594 127.925 122.55 128.731C125.506 129.538 126.85 131.15 125.775 132.763Z"
                                    fill="#699635" />
                                <path
                                    d="M120.4 120.938C120.4 120.938 126.85 129.537 140.556 141.094L140.288 149.156L149.694 146.469L149.963 137.869C149.963 137.869 135.988 124.431 132.494 117.981C132.763 117.981 128.194 119.863 120.4 120.938Z"
                                    fill="#F9F3D9" />
                                <path
                                    d="M123.625 5.375C123.625 21.7687 102.394 48.375 86 48.375C69.6063 48.375 48.375 24.4562 48.375 8.0625C48.375 -8.33125 50.7938 8.0625 67.1875 8.0625C69.6062 8.0625 80.8938 0 86 0C89.4937 0 99.4375 8.0625 104.812 8.0625C119.056 8.0625 123.625 -6.9875 123.625 5.375Z"
                                    fill="#DBB471" />
                                <path
                                    d="M81.1625 35.7438C79.55 36.8188 71.7562 36.0125 69.3375 37.8938C66.1125 40.3125 70.6813 45.9563 67.4563 49.45C61.0063 55.9 55.9 63.1563 57.5125 67.1875C60.2 74.175 65.3062 54.2875 69.875 61.8125C74.4437 69.3375 73.6375 56.7063 80.625 63.1563C87.6125 69.6063 81.7 51.6 88.4188 59.3938C95.1375 67.1875 95.4062 76.0563 100.244 76.0563C105.887 76.0563 94.3313 54.825 101.856 59.9313C109.381 65.0375 117.713 63.1563 110.188 55.3625C107.769 52.9438 104.544 48.6438 100.781 44.8813C99.4375 43.5375 100.513 36.55 99.1688 35.475C97.5563 34.1313 93.525 38.4313 91.9125 37.625C88.6875 36.0125 89.7625 38.9688 84.925 39.775C81.7 40.85 84.3875 33.5938 81.1625 35.7438Z"
                                    fill="#89664C" />
                                <path
                                    d="M50.2562 51.6C60.7375 41.6563 79.8187 47.3 91.1062 52.1375C102.125 56.975 108.844 41.6563 101.856 33.8625C97.825 29.2938 91.375 34.6688 88.9562 37.8938C86.2687 41.1188 84.1187 44.6125 81.1625 47.8375C76.8625 52.675 66.3812 48.6438 69.6062 41.925C73.1 34.9375 87.8812 43 92.7187 44.3438C102.125 47.0313 112.069 48.1063 121.475 44.6125C124.7 43.5375 123.356 38.1625 120.131 39.5063C109.65 43.2688 99.1687 40.85 89.225 37.625C84.1187 36.0125 76.0562 31.7125 70.6812 34.1313C55.3625 41.3875 69.6062 62.0813 82.5062 54.0188C85.4625 52.1375 87.3437 49.1812 89.4937 46.4937C90.8375 44.8812 97.5562 34.6688 99.1687 39.775C99.975 42.4625 99.1687 47.3 96.2125 48.1063C94.3312 48.9125 90.5687 46.225 88.6875 45.6875C85.1937 44.3438 81.7 43.2688 77.9375 42.4625C67.9937 40.3125 54.5562 40.85 46.7625 48.1063C44.075 50.2563 47.8375 54.0188 50.2562 51.6Z"
                                    fill="#E8E8E8" />
                                <path
                                    d="M120.4 1.34375C117.713 3.49375 112.875 8.0625 104.813 8.0625C99.4375 8.0625 89.4938 0 86 0C80.8938 0 69.6063 8.0625 67.1875 8.0625C58.3188 8.0625 53.4813 3.49375 51.0625 1.6125C51.0625 1.6125 54.0188 13.7063 63.1563 19.8875C69.875 24.1875 69.0688 19.0813 72.5625 14.2438C76.0563 9.40625 78.475 6.9875 84.6563 19.0812C87.8813 25.8 94.0625 0.80625 98.9 8.86875C107.769 23.65 110.994 15.5875 120.4 1.34375Z"
                                    fill="#89664C" />
                                <path
                                    d="M140.825 140.825L140.556 148.888L149.962 146.2L150.231 137.6C150.231 137.869 144.587 140.019 140.825 140.825ZM133.837 156.95V164.744L144.319 161.519V153.456C144.05 153.187 141.362 154.8 133.837 156.95ZM31.4437 159.1L32.25 166.625L22.8437 164.475L22.3062 156.681C22.0375 156.681 25.8 158.025 31.4437 159.1Z"
                                    fill="#DBB471" />
                            </g>
                            <defs>
                                <clipPath id="clip0_18_2">
                                    <rect width="172" height="172" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                </div>
            </section>
            <section id="why_us">
                <h1 class="titel">Why Choose Us</h1>
                <div class="cards">
                    <whyCard class="why-us-card" titel="Fast Recovery Process"
                        content="We streamline the process, so you don’t have to wait months to get what’s yours.">
                        <template #icon>
                            <div class="icon">
                                <img :src="time_icon" alt="time icon">
                            </div>
                        </template>
                    </whyCard>
                    <whyCard class="why-us-card-center" titel="Fast Recovery Process"
                        content="We streamline the process, so you don’t have to wait months to get what’s yours.">
                        <template #icon>
                            <div class="icon">
                                <img :src="no_fee_icon" alt="fee icon">
                            </div>
                        </template>
                    </whyCard>
                    <whyCard class="why-us-card" titel="Fast Recovery Process"
                        content="We streamline the process, so you don’t have to wait months to get what’s yours.">
                        <template #icon>
                            <div class="icon">
                                <img :src="support_icon" alt="fee icon">
                            </div>
                        </template>
                    </whyCard>
                </div>
            </section>
            <section id="works">
                <h1 class="titel">How it works</h1>
                <workCard class="card to_left"
                    :description='"We use advanced technology to identify funds and surpluses that may be owed to you."'
                    :titel="'1.Identify Your Surplus'" :img_source="research_image" />
                <workCard class="card to_right"
                    :description='"Our team of experts will verify your eligibility and gather all necessary documentation."'
                    :titel="'2.Verification Process'" :img_source="verification_image" />
                <workCard class="card to_left"
                    :description='"We use advanced technology to identify funds and surpluses that may be owed to you."'
                    :titel="'3.Claim Submission'" :img_source="calculate_image" />
                <workCard class="card to_right"
                    :description='"Once your claim is approved, the funds are transferred directly to your account."'
                    :titel="'4.Get Paid'" :img_source="receive_funds_image" />

                <!-- <div class="card to_right">
                    <h3 class="card-header to_center ">4.Get Paid</h3>
                    <p class="card-content" >“Once your claim is approved, the funds are transferred directly to your account.”</p>
                </div> -->
            </section>
            <section id="experience">
                <div class="experience-holder">
                    <div class="left">
                        <p class="about-text" >About us</p>
                        <p class="years-text">We have many years of experience</p>
                        <p class="reclaim-text" >We are a small team dedicated to helping our clients reclaim what is rightfully theirs.</p>
                        <secondActionButton height="auto" fontSize="20px" borderRadius="10px" @click="$router.push({name : 'index-about'})"  InnerText="Get to know us" />

                    </div>
                    <div class="right">
                        <img :src="employe" alt="">
                    </div>
                </div>
            </section>

        </template>
    </indexLayout>
</template>

<script setup>
import ScrollReveal from 'scrollreveal';
import { useHead } from '@vueuse/head';

useHead({
    title: 'Instaswiss - Get What’s Rightfully Yours',
    meta: [
        {
            name: 'description',
            content: 'Instaswiss helps U.S. residents recover unclaimed funds and surpluses quickly and easily. Start your recovery today!'
        },
        {
            name: 'keywords',
            content: 'unclaimed funds, recovery, Instaswiss, U.S. residents, financial recovery'
        },
        {
            property: 'og:title',
            content: 'Instaswiss - Get What’s Rightfully Yours'
        },
        {
            property: 'og:description',
            content: 'Instaswiss helps U.S. residents recover unclaimed funds and surpluses quickly and easily. Start your recovery today!'
        },
        {
            property: 'og:image',
            content: '/path/to/your/image.jpg' // Update with the path to your OG image
        },
        {
            property: 'og:url',
            content: 'https://www.instaswiss.us'
        }
    ]
});
</script>

<script>
import indexLayout from '../index-layout.vue';
import actionButton from '@/components/index/action-button.vue';
import secondActionButton from '@/components/index/second-action-button.vue';
import happy_customer_image from "@/assets/images/happy_customer.jpg"
import employe from "@/assets/images/employer.jpg"
import workCard from '@/components/index/work-card.vue';

import whyCard from '@/components/index/why-card.vue';


import research_image from "@/assets/images/research.jpg"
import calculate_image from "@/assets/images/calculate.jpg"
import verification_image from "@/assets/images/verification.jpg"
import receive_funds_image from "@/assets/images/receive_funds.jpg"

import time_icon from "@/assets/images/time-management.png"
import no_fee_icon from "@/assets/images/no-fee.png"
import support_icon from "@/assets/images/expert_support.png"
export default {
    
    
    components: {
        indexLayout,
        actionButton,
        workCard,
        whyCard,
        secondActionButton,
    },
    data() {
        return {
            happy_customer_image: happy_customer_image,
            research_image: research_image,
            calculate_image: calculate_image,
            verification_image: verification_image,
            receive_funds_image: receive_funds_image,
            time_icon: time_icon,
            no_fee_icon: no_fee_icon,
            support_icon: support_icon,

            employe:employe
        }
    },
    mounted() {
        const sr = ScrollReveal();

        //HERO SECTION
        sr.reveal('.image-happy-customer-slide', {
            duration: 1000,
            origin: 'bottom',
            distance: '300px',
            easing: 'ease-in-out',
        });
        sr.reveal('.hero-section-main-titel', {
            duration: 500,
            origin: 'bottom',
            distance: '150px',
            easing: 'ease-in-out',
        });
        sr.reveal('.hero-section-second-titel', {
            duration: 750,
            origin: 'bottom',
            distance: '150px',
            easing: 'ease-in-out',
        });
        sr.reveal('.hero-section-button', {
            duration: 1000,
            origin: 'bottom',
            distance: '150px',
            easing: 'ease-in-out',
        });

        //Explained year
        sr.reveal('.explained-main-titel', {
            duration: 750,
            origin: 'bottom',
            distance: '150px',
            easing: 'ease-in-out',
        });
        sr.reveal('.explained-description', {
            duration: 500,
            origin: 'bottom',
            distance: '150px',
            easing: 'ease-in-out',
        });

        //How it works
        sr.reveal('.to_left', {
            duration: 500,
            origin: 'left',
            distance: '150px',
            easing: 'ease',
        });
        sr.reveal('.to_right', {
            duration: 500,
            origin: 'right',
            distance: '150px',
            easing: 'ease',
        });

        //Why us
        sr.reveal('.why-us-card', {
            duration: 750,
            origin: 'bottom',
            distance: '300px',
            easing: 'ease',
        });
        sr.reveal('.why-us-card-center', {
            duration: 500,
            origin: 'bottom',
            distance: '150px',
            easing: 'ease',
        });

    }
}
</script>
<!-- Why choose us ? -->
<style scoped>
#why_us {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    gap: 30px;

    padding: 50px 5% 0% 5%;
    box-sizing: border-box;

    width: 100%;
    padding-bottom: var(--footer-spacing);
}

#why_us .titel {
    font-size: var(--titel-section-font-size);
    line-height: var(--titel-section-line-height);
}

#why_us .cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 5%;
    box-sizing: border-box;
}

#why_us .icon {
    width: 25%;
    height: auto;
}

#why_us .icon img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 750px) {
    #why_us .cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 20px;
    }

    #why_us .icon img {
        width: 75%;
        height: auto;
    }
}
</style>

<!-- Hero section style -->
<style scoped>
#hero-section {
    position: relative;
    min-height: 500px;
    height: 80vh;
    width: 100%;

    background-color: #bee3f4;

    display: grid;
    grid-template-columns: 1fr 1fr;

    padding: 5%;
    padding-bottom: 15%;
    box-sizing: border-box;

    z-index: 8;
}

#hero-section .triangles {
    position: absolute;
    height: 20%;
    width: 20%;
    bottom: 0%;
    top: 5%;
}

#hero-section .text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    gap: 5%;
    padding-top: 8%;
    box-sizing: border-box;
}

#hero-section .text-content h1 {
    font-size: 4vw;
    width: 100%;
    text-align: left;
    line-height: 60px;
}

#hero-section .text-content h5 {
    font-size: 1.5vw;
    width: 100%;
    font-weight: 500;
    text-align: left;
}

#hero-section .image {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    height: 100%;
}

#hero-section .image .half-circle {
    position: absolute;
    bottom: 0%;
    width: 120%;
    height: 50%;

    z-index: -5;
}

#hero-section .image img {
    width: auto;
    height: calc(52vh);
    min-height: 300px;

    z-index: 10;
}

@media screen and (max-width: 750px) {
    #hero-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        height: 80vh;
        gap: 5vh;
    }

    #hero-section .triangles {
        position: absolute;
        height: 20%;
        width: 20%;
        left: 0%;
        top: 5%;

    }

    #hero-section .image img {
        width: auto;
        height: 30vh;
        min-height: 300px;

        z-index: 10;
    }

    #hero-section .text-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 3vh;
        padding-top: 8%;
        box-sizing: border-box;
    }

    #hero-section .text-content h1 {
        font-size: 6vw;
        line-height: 40px;
    }

    #hero-section .text-content h5 {
        font-size: 4vw;
    }
}
</style>

<!-- explained section -->
<style scoped>
#explained {
    padding: 100px 5% 100px 5%;

    box-sizing: border-box;
    height: auto;
    min-height: 400px;

    overflow: hidden;
    background-color: var( --light-gray);

    display: flex;
    justify-content: center;
    align-items: center;


}

#explained h1 {
    font-size: 20px;
    color: white;
}

#explained h3 {
    font-size: 16px;
    color: white;
    font-weight: 400;
}

#explained .explained-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    background-color: #ffb703;
    border-radius: 26px;

    padding: 5%;
    
    box-sizing: border-box;

}
#explained .explained-holder  .left{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 50%;
    gap: 15px;
}
#explained .explained-holder  .right{
    width: 50%;
    
}

@media screen and (max-width: 500px) {
    #explained {
        min-height: 400px;
        box-sizing: border-box;
    }

  
    #explained .explained-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        background-color: #ffb703;
        border-radius: 26px;

        box-sizing: border-box;

    gap: 20px;


    }

    #explained .explained-holder  .left{
        width: 100%;
    }
    #explained .explained-holder  .right{
        width: 100%;
    }
}

@media screen and (max-width: 420px) {
    #explained h3 {
        font-size: 16px;
    }
}
</style>

<!-- how it works -->
<style scoped>
#works {
    padding: 8%;
    box-sizing: border-box;
    width: 100%;

    background-color: var(--dark-blue);

    /* display: grid;
            grid-template-columns: 1fr 1fr;
            place-content: center;
            gap: 5vh; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    gap: 50px;
}

#works .card {
    width: 35%;
    margin-left: -30%;
}

#works .titel {
    color: white;
    font-size: var(--titel-section-font-size);
    line-height: var(--titel-section-line-height);
}

#works .to_right {
    margin-left: 30%;
}

@media screen and (max-width: 1000px) {
    #works .card {
        width: 50%;
        margin-left: -50%;
    }

    #works .to_right {
        margin-left: 50%;
    }
}

@media screen and (max-width: 750px) {
    #works .card {
        width: 75%;
        margin-left: 0%;
    }

    #works .to_right {
        margin-left: 0%;
    }

}
</style>

<style scoped>
#experience{
    padding: 5%;
    box-sizing: border-box;

    min-height: 500px;
    background-color: var(--light-gray);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    padding-bottom: var(--footer-spacing);

    
}
.experience-holder{
    width: 100%;
    height: 100%;
    border-radius: 20px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    padding: 5%;
    box-sizing: border-box;

    gap: 10px;
} 
#experience .about-text{
    color : var( --light-blue);
    font-weight: 800;
    font-size: 20px;
}
#experience .years-text{
    font-size: 24px;
    font-weight: 500;
}
#experience .left{
    width: 50%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;


    gap: 15px;

    text-align: left;

}
#experience .right{
    width: 50%;
    height: auto;
}
#experience .right img{
    width: 100%;
    height: auto;

    border-radius: 15px;
}
@media screen and (max-width: 750px){
    
    .experience-holder{
        width: 100%;
        height: 100%;
        border-radius: 20px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        padding: 5%;
        box-sizing: border-box;

        gap: 10px;
    } 
    #experience .left{
        width: 100%;
    }
    #experience .right{
        width: 100%;
        height: auto;
    }
    #experience .right img{
        width: 100%;
        height: auto;
    }
}

</style>