<template >
    <indexLayout>
        <template #content >
            <legalNav></legalNav>
            <div class="legal-content">
                <slot name="content" ></slot>
            </div>
        </template>
    </indexLayout>
</template>
<script>
import indexLayout from '../../index-layout.vue';
import legalNav from './legal-nav.vue';
export default {
    components : {
        indexLayout,
        legalNav,
    }
}
</script>
<style >
    .legal-content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        width: 100%;
        padding: 0% 8%;
        box-sizing: border-box;

        padding-bottom: var(--footer-spacing);
    }
</style>