<template >
    <nav v-if="windowWidth" class="desk-navbar" >
        <div class="head">
            <p>BackOffice</p>
        </div>
        <div class="links" >
            <div v-for="l,index in links" :key="index" :class="{ 'slected_link': $route.path.includes(l.include_highlighter)  }" class="selected_link link">
                <div class="link_icon">
                    <img :src="l.icon" alt="">
                </div>
                <router-link  class=" link_text" :to="l.url"   @click="l.handelClick" >{{ l.text }}</router-link>
            </div>
           
            <!-- <rollableList Header="Customers" :links="[
                {content : 'List all',link :'/backoffice/customers'},
                {content : 'Create',link :'/backoffice/customers/create'},
            ]">
                        
            </rollableList> -->
        </div>
    </nav>
    <nav class="mobile-navbar" v-else >
        <p  class="head">SMSPEOPLE</p>
        <div class="links">
            <router-link v-for="l,index in links" :key="index" @click="l.handelClick" class=" link_text" :to="l.url" >{{ l.text }}</router-link>
        </div>
    </nav>
</template>
<script>
// import rollableList from '@/components/rollable-list.vue';
import customers_icon from "@/assets/icons/customers.png"
import logout_icon from "@/assets/icons/logout.png"
import { useInfoStore } from "@/store";
import { logoutBackoffice } from "@/api.service";
export default {
    name : "backoffice-nav",
    components:{
        // rollableList
    },
    data(){
        return{
            infoStore : useInfoStore(),
            links : [
                {url : '/backoffice/customers',text : "Customers",icon : customers_icon, include_highlighter : "customers",handelClick:null},
                {url : '/backoffice/login',text : "Logout",icon : logout_icon, include_highlighter : "logout",handelClick:()=>{logoutBackoffice()}},
            ]
        }
    },
    mounted(){
        // window.addEventListener('resize', ()=>{
        //     this.widthLarge = window.innerWidth >= 850 ? true : false;
        // });
    },
    props : ["windowWidth"],
    methods : {
        logout(){
            // window.localStorage.clear();
            console.log("logout")
        }
    }
}
</script>
<style scoped>
.slected_link{
    background-color: #023047;
}
.desk-navbar{
        position: fixed;
        top: 0;
        left: 0;
        width: 20%;

        height: 100vh;

        background-color: #2a6f97;
        color: white;

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 2%;

       
}
.desk-navbar .head{
    font-weight: 600;
    font-size: 22px;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 2%;
    box-sizing: border-box;

}
.desk-navbar .links{
    /* background-color: #8ecae6;
    width: 100%;
    height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    width: 100%;
    height: 100%;
}
.desk-navbar .links .link{
    height: 50px;
    width: 100%;


    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 5%;
}
.desk-navbar .links .link .link_text{
    
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    text-decoration: none;
    color:white;
    font-size: 20px;

}
.desk-navbar .links .link:hover{
    background-color: #023047;
}
.desk-navbar .links .link .link_icon{
    width: 50px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}
.desk-navbar .links .link .link_icon img{
    width: 80%;
    height: auto;
}

</style>

<style scoped >

.mobile-navbar{
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 50px;

    background-color: #2a6f97;
    color: white;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0% 2%;
    box-sizing: border-box;

    z-index: 99;

}
.mobile-navbar .head{
    font-weight: 600;
}
.mobile-navbar .links{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 80%;
    gap: 15px;
}
.mobile-navbar .links .link_text{
    text-decoration: none;
    color: white;
}
.mobile-navbar .links .link_text:hover{
    text-decoration: underline;

}
</style>