<template>
  <button :style="{ height: height, width: width }">{{ InnerText }}</button>
</template>
<script>
export default {
  props: {
    InnerText: String,
    height: String,
    width: String,
  },
};
</script>
<style scoped>
button {
  border: none;
  background-color: var( --dark-orange);
  
  padding: 2% 6%;
  box-sizing: border-box;
  
  color: white;
  font-size: calc((1vw + 1vh));
  cursor : pointer;
}
button:hover{
  background-color: vat(--dark-blue-hover);
}

</style>
