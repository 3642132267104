<template>
    <button class="contact-button" :style="{ height: height, width: width,fontSize : fontSize,borderRadius:borderRadius }">{{ InnerText }}</button>
</template>
<script>
export default {
props: {
    InnerText: String,
    height: String,
    width: String,
    fontSize : String,
    borderRadius : String,
},
};
</script>
<style scoped>
.contact-button{
    width: auto;
    height: auto;
    border: none;

    background-color: var(--dark-blue);
    padding: 5%;
    box-sizing: border-box;
    color: white;

    font-size: 25px;
}
</style>