<template>
    <div class="backoffice-base-holder" >
            <backofficeLayout :hideNav="false">

                <template #content >
                    <section class="form-holder" >
                        <nav class="list-header">
                            <p>Add new customer</p>
                        </nav>
                        <form   @submit.prevent="create" class="backoffice-form">
                        <p class="form-title">Create new customer</p>
                            <div class="input-container">
                                <input v-model="phone_number" type="text" placeholder="Enter phone number">
                            </div>
                            <div class="input-container">
                                <input v-model="first_name" type="text" placeholder="Enter first name">
                            </div>
                            <div class="input-container">
                                <input v-model="last_name" type="text" placeholder="Enter last name">
                            </div>
                            <div class="input-container">
                                <input v-model="town" type="text" placeholder="Enter town">
                            </div>
                            <div class="input-container">
                                <input v-model="company" type="text" placeholder="Enter company">
                            </div>
                            
                        <actionButton  text="Create customer" />
                    </form>
                    </section>
                </template>
            </backofficeLayout>
    </div>
</template>
<script>
import actionButton from '@/components/action-button.vue';
import backofficeLayout from '.././backoffice-layout.vue';
import { useInfoStore } from '@/store';
import { createCustomer,getToken } from '@/api.service';
export default {
    name:"customer-create",
    components: {
        backofficeLayout,
        actionButton
    },
    data(){
        return{
           first_name : null,
           last_name : null,
           town : null,
           company : null,
           phone_number : null,
           infoStore : useInfoStore(),
        }
    },
    methods : {
        create(){
            if(this.first_name && this.last_name && this.town && this.company && this.phone_number){
                try{
                createCustomer({
                    "first_name" : this.first_name,
                    "last_name" : this.last_name,
                    "town" : this.town,
                    "company" : this.company,
                    "phone_number" : this.phone_number,
                })
                .then(data=>{
                    console.log("received",data);
                    this.$router.replace({name : 'backoffice-customers'})
                })

            }catch(err){
                console.log(err)
            }
            }
            return;
           
        }
    },
    beforeMount(){
        if(getToken() == null){
            this.$router.replace("/backoffice/login")
        }
    }
}
</script>
<style scoped>
.list-header{
    height: 8%;
    width: 100%;

    background-color: #023047;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    padding: 2%;
    box-sizing: border-box;
}
.list-header p{
    font-size: 20px;
    color: #8ecae6;
}

.form-holder{
    width: 100%;
    height: 100vh;
    min-height: 600px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    box-sizing: border-box;
}
.backoffice-form {
    height: auto;
    width: 100%;
    
    background-color: white;
    margin: auto;
    display: block;
    padding: 1rem;
    max-width: 350px;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.form-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
}

.input-container {
  position: relative;
}

.input-container input, .form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px 0;
}

.input-container input {
  background-color: #fff;
  padding: 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 300px;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.submit {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: #4F46E5;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  width: 100%;
  border-radius: 0.5rem;
  text-transform: uppercase;
}

.signup-link {
  color: #6B7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}

.signup-link a {
  text-decoration: underline;
}


</style>