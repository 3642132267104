import { createApp } from 'vue'
import App from './App.vue'

import routes from './routes'
import { createRouter,createWebHistory  } from 'vue-router'

import { createPinia } from 'pinia'

import { createHead } from '@vueuse/head';
const head = createHead();

const router = new createRouter({
    mode : "hash",
    history: createWebHistory(),
    routes, // short for `routes: routes`
    
})


import mitt from 'mitt'
const emitter = mitt();

const pinia = createPinia()

const app = createApp(App)

app.config.globalProperties.emitter = emitter;
app.use(router);
app.use(pinia);
app.use(head)

app.mount('#app');

export default{router,emitter}
