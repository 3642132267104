<template>
    <section v-if="image" id="document-display">
      <!-- Display the image if blobUrl is available -->
      <img v-if="blobUrl" :src="blobUrl" alt="" @load="loaded">
      <!-- Add an error message if something went wrong -->
      <p v-if="errorMessage">{{ errorMessage }}</p>
    </section>
    <section v-else id="document-display">
      <!-- Display the image if blobUrl is available -->
      <VuePDF v-if="pdf" :pdf="pdf" text-layer annotation-layer />
      <!-- Add an error message if something went wrong -->
      <p v-if="errorMessage">{{ errorMessage }}</p>
    </section>
  </template>
  
  <script>
  import { backofficeCustomerDocumentRetrieve,CustomerDocumentRetrieve } from '@/api.service';


  export default {
    data() {
      return {
        blobUrl: null,
        errorMessage: null,
        image : true, 
        pdf : null// To display an error message if needed
      };
    },
    async mounted() {
      try {
        var blob;
        if(this.$route.params.id != -1){
          blob = await backofficeCustomerDocumentRetrieve(
          this.$route.params.id,
          this.$route.params.document_type
        );
        }else {
          blob = await CustomerDocumentRetrieve(
          this.$route.params.document_type
        );
        }
        
        console.log("here is the blob",blob,blob.type)
  
        // Check if the blob is of the expected type
        if (blob.type.startsWith('image/') ) {
          this.blobUrl = URL.createObjectURL(blob);
        } else if( blob.type === 'application/pdf') {
          const url = URL.createObjectURL(blob);

          window.location.href = url;

          URL.revokeObjectURL(url);
        }else{
          
          console.error('Unexpected blob type:', blob.type);
          this.errorMessage = 'Failed to load document. Please try again.';
        }
      } catch (error) {
        console.error('Error retrieving document:', error);
        this.errorMessage = 'An error occurred while retrieving the document.';
      }
    },
    methods: {
      loaded() {
        if (this.blobUrl) URL.revokeObjectURL(this.blobUrl);
      },
    },
    components : {
    }
  };
  </script>
  
  <style scoped>
  #document-display {
    height: 80vh;
    width: 100vw;
  }
  
  #document-display img {
    height: 100%;
    width: auto;
  }
  </style>
  