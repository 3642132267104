<template>
        <div class="questions-square">
            <p style="font-size: 26px;" >Questions {{ QuestionNumber }}</p>
            <div style="display:flex;justify-content:center;align-items: center;flex-direction: column; height: 80%;" >
                <p style="font-size: 36px; font-weight: 600;" >{{ Question }}</p>
                <p style="font-size: 36px; font-weight: 400;" >{{ QuestionValue }}</p>
            </div>
            <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 1vh;" >
                <div style="display: flex;justify-content: center;align-items: center;gap: 2%; width: 100%;" >
                    <slot name="buttons" ></slot>
                </div>
                <slot name="dknButton" ></slot>
            </div>
        </div>
</template>
<script>
export default {
    name: "questions-view",
    data() {
        return {
            questions: [
                "Is you complete name",
                "Do you reside or have you ever resided in ",
                "Have you ever done business with this company"
            ]
        }
    },
    components : {
    },
    props : {
        QuestionNumber : Number,
        Question: String,
        QuestionValue: String,
    }
}
</script>
<style>
.questions-square {
    background-color: #f8f9fa;
    border: solid 1px #dee2e6;
    height: 90vh;
    width: 90vw;

    padding: 4%;
    box-sizing: border-box;

    border-radius: 26px;

}
</style>