<template>
    <indexLayout>
        <template #content>
            <section id="mission">
                <div class="blurry_div"></div>
                <h1 class="titel">
                    <p class="at_instaswiss">At Instaswiss,</p>
                    <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">
                        <p class="believe">we believe</p>
                        <p class="power">in the power of rightful ownership.</p>
                    </div>
                </h1>
                <p class="mission_description">
                    Our mission is to empower U.S.
                    residents by reconnecting them with the funds they are owed, helping them achieve financial peace of
                    mind
                </p>
            </section>
            <section id="story">
                <h1 class="titel">Our story</h1>
                <p class="paragprah" >
                    <span>
                        Founded by experts in financial recovery, Instaswiss was born out of a desire to make the often
                    complicated and frustrating process of claiming unclaimed funds simple and accessible. 
                    </span>
                    <br>
                    <span>
                        Since our inception, we’ve helped thousands of Americans recover millions of dollars that they didn’t even
                    know they were owed.
                    </span>
                </p>
            </section>
            <section id="claim">
                <h1 class="titel" >Ready to claim what's yours?</h1>
                <p>let's talk about what we can get you.</p>
                <actionButton style="margin-top: 20px;" @click="goToContactPage" InnerText="Get in touch"  />
            </section>
        </template>
    </indexLayout>
</template>
<script setup >
import { useHead } from '@vueuse/head';
useHead({
    title: 'About Us - Instaswiss',
    meta: [
        {
            name: 'description',
            content: 'Learn more about Instaswiss, our mission to help U.S. residents recover unclaimed funds, and our story of financial recovery expertise.'
        },
        {
            name: 'keywords',
            content: 'Instaswiss, about us, financial recovery, unclaimed funds, U.S. residents'
        },
        {
            property: 'og:title',
            content: 'About Us - Instaswiss'
        },
        {
            property: 'og:description',
            content: 'Discover Instaswiss’s mission and story. We help U.S. residents recover unclaimed funds with expertise and dedication.'
        },
        {
            property: 'og:image',
            content: '/path/to/your/about-image.jpg' // Update with the path to your OG image
        },
        {
            property: 'og:url',
            content: 'https://www.instaswiss.us/about'
        },
        {
            name: 'twitter:title',
            content: 'About Us - Instaswiss'
        },
        {
            name: 'twitter:description',
            content: 'Learn more about Instaswiss, our mission, and our story. Helping U.S. residents recover unclaimed funds efficiently.'
        },
        {
            name: 'twitter:image',
            content: '/path/to/your/about-image.jpg' // Update with the path to your Twitter image
        }
    ]
});
</script>
<script>
import actionButton from "@/components/index/action-button.vue";
import about_picture from "@/assets/images/about.jpg"
import indexLayout from '../index-layout.vue';
import ScrollReveal from 'scrollreveal';
export default {
    components: {
        indexLayout,
        actionButton
    },
    data() {
        return {
            about_picture: about_picture
        }
    },
    mounted() {

        const sr = ScrollReveal();

        //mission section
        sr.reveal('.at_instaswiss', {
            duration: 500,
            origin: 'bottom',
            distance: '300px',
            easing: 'ease-in-out',
        });
        sr.reveal('.believe', {
            origin: 'bottom',
            distance: '300px',
            easing: 'ease-in-out',
            interval: 20000
        });
        sr.reveal('.power', {
            duration: 1000,
            origin: 'bottom',
            distance: '300px',
            easing: 'ease-in-out',
        });
        sr.reveal('.mission_description', {
            duration: 1200,
            origin: 'bottom',
            distance: '300px',
            easing: 'ease-out',
            opacity: '0'
        });
    },
    methods : {
        goToContactPage(){
            this.$router.replace("/contact")
        }
    }
}

</script>



<!-- Mission -->
<style scoped>
#mission {
    width: 100%;
    height: 100vh;
    min-height: 600px;

    position: relative;
    overflow: hidden;

    background-image: url("@/assets/images/about.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0% 8%;
    box-sizing: border-box;

}

#mission .titel {
    color: white;
    font-size: var(--titel-section-font-size);
    /* text-shadow: 2px 2px 9px #000000; */
    z-index: 10;
}

#mission .titel .believe {
    color: var(--dark-orange);
    text-decoration: underline;
}

#mission .blurry_div {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    z-index: 5;

    background:
        linear-gradient(rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7))
}

#mission .mission_description {
    color: white;
    font-weight: 300;
    font-size: 2vw;
    z-index: 5;
}

@media screen and (max-width: 750px) {
    #mission .mission_description {
        font-size: 4vw;
    }
}
</style>

<!-- Story -->
<style scoped>
#story{
    margin-top: 50px;
    padding: 0% 8%;
    box-sizing: border-box;
    min-height: 300px;
}
#story .titel {
    color: black;
    font-size: var(--titel-section-font-size);
    /* text-shadow: 2px 2px 9px #000000; */
    z-index: 10;
    line-height: 50px;
    
}
#story .paragprah{
    text-align: center;            /* Justify text for a clean look */
    text-indent: 30px; 
}

</style>

<!-- Get in touch -->
<style scoped>
#claim{
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 8%;
    padding-bottom: var(--footer-spacing);
    gap: 10px;
    box-sizing: border-box;
    background-color: var(--light-blue);
}
</style>