import notFound from "./views/index/content/not-found.vue";
//Questions
import questions from "./views/questions.vue"
import match from "./views/match.vue";
import callyou from "./views/callyou.vue";
import notEligible from "./views/notEligible.vue";
import AlreadyAnswered from "./views/AlreadyAnswered.vue";
import documentsInputs from "./views/documentsInputs.vue";
import fixDocuments from "./views/fix-documents.vue";
// import home from "./views/backoffice/content/home.vue";

//BackOffice
import loginView from "./views/backoffice/auth/login-view.vue";
import customers from "./views/backoffice/content/customers.vue";
import customerCreate from "./views/backoffice/content/customer-create.vue";
import customerEdit from "./views/backoffice/content/customer-edit.vue";
import customerDocuments from "./views/backoffice/content/customer-documents.vue";

//Index pages
import homePage from "./views/index/content/home-page.vue";
import aboutPage from "./views/index/content/about-page.vue";
import contactPage from "./views/index/content/contact-page.vue";
    //legal
    import termsConditions from "./views/index/content/legal/content/terms-conditions.vue";
    import privacyPolicy from "./views/index/content/legal/content/privacy-policy.vue";

const routes = [
    {path: '/',redirect: '/home' },
    {path: '/not-found',component : notFound,name:"index-not-found" },

    //Questions
    { path: '/questions/:token/', component: questions ,name:"questions",props:true},
    { path: '/questions/match/', component: match ,name:"match"},
    { path: '/questions/callyou/', component: callyou ,name:"callyou"},
    { path: '/questions/noteligible/', component: notEligible ,name:"notEligible"},
    { path: '/questions/wait/', component: AlreadyAnswered ,name:"wait"},
    { path: '/questions/match/documents/', component: documentsInputs ,name:"fill_document"},
    { path: '/questions/match/documents/fix/', component: fixDocuments ,name:"fix_document"},
    
    //BackOffice
    { path: '/backoffice/login', component: loginView ,name:"backoffice-login"},
    { path: '/backoffice/customers', component: customers ,name:"backoffice-customers"},
    { path: '/backoffice/customers/create', component: customerCreate ,name:"backoffice-customers-create"},
    { path: '/backoffice/customers/:id/', component: customerEdit ,name:"backoffice-customers-edit"},
    { path: '/backoffice/customers/:id/documents/:document_type/', component: customerDocuments ,name:"backoffice-customers-documents"},
    { path: '/backoffice/', redirect : "/backoffice/customers/"},
    
    //Index pages
    { path: '/home', component: homePage ,name:"index-home"},
    { path: '/about', component: aboutPage ,name:"index-about"},
    { path: '/contact', component: contactPage ,name:"index-contact"},
        //legal
        { path: '/contact/terms-conditions', component: termsConditions ,name:"index-legal-termsCondition"},
        { path: '/contact/privacy-policy', component: privacyPolicy ,name:"index-legal-privacyPolicy"},


];


export default routes;