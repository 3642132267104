<template lang="">
    <indexLayout>
        <template #content>
           <section id="fix-documents" >
                    <div class="rejection-header">
                        <p style="font-size:26px;font-weight:500;">Invalid Documents </p>
                        <p>
                            Thank you for your recent submission. Unfortunately, we were unable to approve the documents provided. You can view the documents you previously uploaded and see the reason for the rejection.

                        </p>

                        <p style="font-weight:500;">
                            we kindly ask you to re-upload the necessary documents at your earliest convenience.
                        </p>
                        <router-link to="/questions/match/documents">
                            <actionButton InnerText="Upload documents"></actionButton>
                        </router-link>
                    </div>
                    <div class="reason-section" >
                        <p style="font-size:26px;font-weight:500;">Rejection reason</p>
                        <p class="reason-text" >
                            {{refusal_reason}}
                        </p>
                        <div class="documents-card-holder" >
                            <p style="font-size:18px;">Your uploaded documents : </p>
                            <div @click="RetreiveDocument('ssc')"  class="file-card" >
                                <p>SSC</p>
                                <i>
                                    <svg width="20" height="20" viewBox="0 0 12 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4 3.5C3.86739 3.5 3.74021 3.55268 3.64645 3.64645C3.55268 3.74021 3.5 3.86739 3.5 4V8C3.5 8.13261 3.55268 8.25979 3.64645 8.35355C3.74021 8.44732 3.86739 8.5 4 8.5H8C8.13261 8.5 8.25979 8.44732 8.35355 8.35355C8.44732 8.25979 8.5 8.13261 8.5 8V7.75C8.5 7.55109 8.57902 7.36032 8.71967 7.21967C8.86032 7.07902 9.05109 7 9.25 7C9.44891 7 9.63968 7.07902 9.78033 7.21967C9.92098 7.36032 10 7.55109 10 7.75V8C10 8.53043 9.78929 9.03914 9.41421 9.41421C9.03914 9.78929 8.53043 10 8 10H4C3.46957 10 2.96086 9.78929 2.58579 9.41421C2.21071 9.03914 2 8.53043 2 8V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H4.25C4.44891 2 4.63968 2.07902 4.78033 2.21967C4.92098 2.36032 5 2.55109 5 2.75C5 2.94891 4.92098 3.13968 4.78033 3.28033C4.63968 3.42098 4.44891 3.5 4.25 3.5H4ZM6.75 3.5C6.55109 3.5 6.36032 3.42098 6.21967 3.28033C6.07902 3.13968 6 2.94891 6 2.75C6 2.55109 6.07902 2.36032 6.21967 2.21967C6.36032 2.07902 6.55109 2 6.75 2H9.25C9.44891 2 9.63968 2.07902 9.78033 2.21967C9.92098 2.36032 10 2.55109 10 2.75V5.25C10 5.44891 9.92098 5.63968 9.78033 5.78033C9.63968 5.92098 9.44891 6 9.25 6C9.05109 6 8.86032 5.92098 8.71967 5.78033C8.57902 5.63968 8.5 5.44891 8.5 5.25V4.56L7.28 5.78C7.13783 5.91248 6.94978 5.9846 6.75548 5.98117C6.56118 5.97775 6.37579 5.89903 6.23838 5.76162C6.10097 5.62421 6.02225 5.43882 6.01883 5.24452C6.0154 5.05022 6.08752 4.86217 6.22 4.72L7.44 3.5H6.75Z"
                                            fill="#77BECA" />
                                    </svg>
                                </i>
                                
                            </div>
                            <div @click="RetreiveDocument('company_document')" class="file-card" >
                                <p>Related Company</p>
                                <i>
                                    <svg width="20" height="20" viewBox="0 0 12 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4 3.5C3.86739 3.5 3.74021 3.55268 3.64645 3.64645C3.55268 3.74021 3.5 3.86739 3.5 4V8C3.5 8.13261 3.55268 8.25979 3.64645 8.35355C3.74021 8.44732 3.86739 8.5 4 8.5H8C8.13261 8.5 8.25979 8.44732 8.35355 8.35355C8.44732 8.25979 8.5 8.13261 8.5 8V7.75C8.5 7.55109 8.57902 7.36032 8.71967 7.21967C8.86032 7.07902 9.05109 7 9.25 7C9.44891 7 9.63968 7.07902 9.78033 7.21967C9.92098 7.36032 10 7.55109 10 7.75V8C10 8.53043 9.78929 9.03914 9.41421 9.41421C9.03914 9.78929 8.53043 10 8 10H4C3.46957 10 2.96086 9.78929 2.58579 9.41421C2.21071 9.03914 2 8.53043 2 8V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H4.25C4.44891 2 4.63968 2.07902 4.78033 2.21967C4.92098 2.36032 5 2.55109 5 2.75C5 2.94891 4.92098 3.13968 4.78033 3.28033C4.63968 3.42098 4.44891 3.5 4.25 3.5H4ZM6.75 3.5C6.55109 3.5 6.36032 3.42098 6.21967 3.28033C6.07902 3.13968 6 2.94891 6 2.75C6 2.55109 6.07902 2.36032 6.21967 2.21967C6.36032 2.07902 6.55109 2 6.75 2H9.25C9.44891 2 9.63968 2.07902 9.78033 2.21967C9.92098 2.36032 10 2.55109 10 2.75V5.25C10 5.44891 9.92098 5.63968 9.78033 5.78033C9.63968 5.92098 9.44891 6 9.25 6C9.05109 6 8.86032 5.92098 8.71967 5.78033C8.57902 5.63968 8.5 5.44891 8.5 5.25V4.56L7.28 5.78C7.13783 5.91248 6.94978 5.9846 6.75548 5.98117C6.56118 5.97775 6.37579 5.89903 6.23838 5.76162C6.10097 5.62421 6.02225 5.43882 6.01883 5.24452C6.0154 5.05022 6.08752 4.86217 6.22 4.72L7.44 3.5H6.75Z"
                                            fill="#77BECA" />
                                    </svg>
                                </i>
                            </div>
                            <div @click="RetreiveDocument('id_card')" class="file-card" >
                                <p>US ID Card</p>
                                <i>
                                    <svg width="20" height="20" viewBox="0 0 12 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4 3.5C3.86739 3.5 3.74021 3.55268 3.64645 3.64645C3.55268 3.74021 3.5 3.86739 3.5 4V8C3.5 8.13261 3.55268 8.25979 3.64645 8.35355C3.74021 8.44732 3.86739 8.5 4 8.5H8C8.13261 8.5 8.25979 8.44732 8.35355 8.35355C8.44732 8.25979 8.5 8.13261 8.5 8V7.75C8.5 7.55109 8.57902 7.36032 8.71967 7.21967C8.86032 7.07902 9.05109 7 9.25 7C9.44891 7 9.63968 7.07902 9.78033 7.21967C9.92098 7.36032 10 7.55109 10 7.75V8C10 8.53043 9.78929 9.03914 9.41421 9.41421C9.03914 9.78929 8.53043 10 8 10H4C3.46957 10 2.96086 9.78929 2.58579 9.41421C2.21071 9.03914 2 8.53043 2 8V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H4.25C4.44891 2 4.63968 2.07902 4.78033 2.21967C4.92098 2.36032 5 2.55109 5 2.75C5 2.94891 4.92098 3.13968 4.78033 3.28033C4.63968 3.42098 4.44891 3.5 4.25 3.5H4ZM6.75 3.5C6.55109 3.5 6.36032 3.42098 6.21967 3.28033C6.07902 3.13968 6 2.94891 6 2.75C6 2.55109 6.07902 2.36032 6.21967 2.21967C6.36032 2.07902 6.55109 2 6.75 2H9.25C9.44891 2 9.63968 2.07902 9.78033 2.21967C9.92098 2.36032 10 2.55109 10 2.75V5.25C10 5.44891 9.92098 5.63968 9.78033 5.78033C9.63968 5.92098 9.44891 6 9.25 6C9.05109 6 8.86032 5.92098 8.71967 5.78033C8.57902 5.63968 8.5 5.44891 8.5 5.25V4.56L7.28 5.78C7.13783 5.91248 6.94978 5.9846 6.75548 5.98117C6.56118 5.97775 6.37579 5.89903 6.23838 5.76162C6.10097 5.62421 6.02225 5.43882 6.01883 5.24452C6.0154 5.05022 6.08752 4.86217 6.22 4.72L7.44 3.5H6.75Z"
                                            fill="#77BECA" />
                                    </svg>
                                </i>
                            </div>
                            
                        </div>
                    </div>
                    <div class="upload-section" >
                        <p style="font-size:26px;font-weight:500;"  >Re-upload Documents for Processing</p>
                        <div class="uploading-holder" >
                            <div class="upload-card" >
                                <p  > SSC </p>
                                <div>
                                    <input  class="input" name="ssc" id="file" type="file" @change='saveSSC'
                                        accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pdf" />
                                        <p v-if="files.ssc != null" >Document accepted please click the button below to confirm it.</p>
                                        <!-- <primaryButton TextContent="Save Related company proof" :positive="true" @click="uploadDoc('ssc')" /> -->
                                </div>
                            </div>
                            <div class="upload-card" >
                                <p> Related company proof </p>
                                <div>
                        
                                    <input class="input" name="company" id="file" type="file" @change='saveDocCompany'
                                        accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pdf" />
                                        <p v-if="files.company_doc != null" >Document accepted please click the button below to confirm it.</p>
                                        <!-- <primaryButton TextContent="Save Related company proof" :positive="true" @click="uploadDoc('company_doc')" /> -->
                                </div>
                            </div>

                            <div class="upload-card" >
                                <p> US ID Card</p>
                                <div>
                        
                                    <input class="input" name="company" id="file" type="file" @change='saveIDCompany'
                                        accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pdf" />
                                    <p v-if="files.id_card != null" >Document accepted please click the button below to confirm it.</p>
                                </div>
                            </div>
                            <primaryButton TextContent="Save update" :positive="true" @click="uploadDoc()" />
                        </div>
                    </div>
                    
           </section>
        </template>
</indexLayout>

</template>
<script>
import indexLayout from './index/index-layout.vue';
import { AuthTokenDelete,UpdateDocuments } from '@/api.service';
import { getRefusal } from '@/api.service';
import primaryButton from '@/components/primary-button.vue';
export default {
    name: "fix-documents-view",
    data() {
        return {
            refusal_reason : "",
            files : {
                ssc : null,
                company_doc : null,
                id_card:null
            }
        }
    },
    components: {
        indexLayout,
        primaryButton
    },
    beforeUnmount() {
        AuthTokenDelete()
    },
    async beforeMount() {
        if (!("AuthToken" in window.localStorage)) {
            this.$router.replace({ name: 'index-not-found' })
        }
        await getRefusal()
        .then((data)=>{
            this.refusal_reason = data.reason
        })
    },
    methods: {
        async RetreiveDocument(doc_type) {
            const { href } = this.$router.resolve({ name: "backoffice-customers-documents", params: { id: -1, document_type: doc_type } })
            window.open(href, '_blank');
        },
        saveSSC(event){
            this.files.ssc = event.target.files[0];
        },
        saveDocCompany(event){
            this.files.company_doc = event.target.files[0];
        },
        saveIDCompany(event){
            this.files.id_card = event.target.files[0];
        },
        async uploadDoc(){
            let files = [];
            console.log("ssc",this.files.ssc)
            console.log("id_card",this.files.id_card)
            console.log("company_doc",this.files.company_doc)
            if(!this.files.ssc && !this.files.id_card && !this.files.company_doc ){
                this.emitter.emit("sidebar-error",{
                        error : true,
                        description : 'Please upload at least one document.'
                    })
                return;
            }
            if(this.files.ssc){
                files.push({
                    type : "ssc",
                    file : this.files.ssc
                })
            }
            if(this.files.company_doc){
                files.push({
                    type : "company_doc",
                    file : this.files.company_doc
                })
            }
            if(this.files.id_card){
                files.push({
                    type : "id_card",
                    file : this.files.id_card
                })
            }
            try{
                await UpdateDocuments(files)
                .then(()=>{
                    this.$router.replace("/questions/callyou/")
                })
                
            }catch(error){
                console.log(error)
            }
        }
       
    }
}
</script>
<style scoped>
#fix-documents {
    height: auto;
    min-height: 600px;
    background-color: #f8f9fa;
    border: solid 1px #dee2e6;
    width: 90vw;

    padding: 4%;
    box-sizing: border-box;

    border-radius: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;

    margin-top: 50px;
    margin-bottom: 50px;

}

#fix-documents .rejection-header {

    height: auto;


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-bottom: solid 1px gray;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

}

#fix-documents .reason-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;

    width: 100%;

    border-bottom: solid 1px gray;

}

#fix-documents .reason-section .reason-text {
    font-weight: 500;
    font-size: 20px;
    background-color: white;

    border-radius: 5px;
    width: 100%;
    text-align: center;

    padding: 10px;
    box-sizing: border-box;

    background-color: var(--dark-blue);
    color: white;
}

#fix-documents .documents-card-holder {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    padding: 5%;
    box-sizing: border-box;
    gap: 10px;

}

#fix-documents .documents-card-holder .file-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    gap: 5px;
    background-color: var(--dark-blue);
    color: white;
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;

    cursor: pointer;
}

#fix-documents .upload-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#fix-documents .upload-card input[type="file"]{
    background-color: var(--dark-blue);
    color: white;
    padding: 5px;
    box-sizing: border-box;

}

#fix-documents .uploading-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
}
#fix-documents .upload-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>