import { defineStore } from 'pinia'


export const useInfoStore = defineStore('InfoStore', {
    state: () => {
        return {
          // all these properties will have their type inferred automatically
          Token: null,
        }
      },
})