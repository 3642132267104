<template lang="">
    <button @click="handelClick" :class="{
      'positive-button': positive === true, 
      'negative-button': positive === false, 
      'neutral-button': positive === -1
    }"  style={{Styling}} >
        {{TextContent}}
    </button>
</template>
<script>
export default {
    name: "primary-button",
    props: [
        "TextContent",
        "Styling",
        "positive" ,
        "handelClick" ,
    ]
}
</script>
<style scoped>
.positive-button {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px #7ae582;
    background-color: #7ae582;
    border-radius: 10px;
    font-size: 1rem;
    color: #31572c;
    transition: all 0.25s;
}

.positive-button:active {
    background-color: #80ed99;
}

.negative-button {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px #e5383b;
    background-color: #e5383b;
    border-radius: 10px;
    font-size: 1rem;
    color: #660708;
    transition: all 0.25s;
}

.negative-button:active {
    background-color: #a4161a;
}
.neutral-button {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px #d76a03;
    background-color: #d76a03;
    border-radius: 10px;
    font-size: 20px;
    color: black;
    transition: all 0.25s;
}

.neutral-button:active {
    background-color: #ec9f05;
}

</style>