<template >
    <button  @click="handelClick" class="action-button">
            {{ text }}
    </button>
</template>
<script>
export default {
    props : ['text',"handelClick"]
}
</script>
<style >
.action-button {
    display: block;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: #023047;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    width: 100%;
    border-radius: 0.5rem;
    text-transform: uppercase;
}

</style>