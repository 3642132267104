<template lang="">
       
    <div class="holder">
    <div class="matchsquare" >
        <p style="font-size:26px;font-weight:500;" >Great, we just need to get a few more informations.</p>
        <div style="display:flex;justify-content:center;flex-direction:column;align-items:center;" >
            <p>{{titel}}</p>
            <slot name="fileInput"></slot>
        </div>
       <slot name="buttons" ></slot>
    </div>
       
    </div>
</template>
<script>
import { useInfoStore } from '@/store';
// import inputFile from '@/components/input-file.vue';
// import primaryButton from '@/components/primary-button.vue';
export default {
    name : "docuemnts-inputs",
    data(){
        return{
            infoStore : useInfoStore()
        }
    },
    components : { 
        // primaryButton
    },
    props : ["titel","inputVar"]
}
</script>
<style lang="">
    
</style>