<template lang="">
    <div class="index-holder " :class="widthLarge ? 'marginTop' : 'marginTop'">
        <indexNavbar :widthLarge="widthLarge" />
        <slot name="content" ></slot>
        <indexFooter></indexFooter>
    </div>
</template>
<script>
import indexNavbar from './index-navbar.vue';
import indexFooter from './index-footer.vue';
export default {
    components : {
        indexNavbar,
        indexFooter
    },
    props : ["hideNav"],
    data(){
        return{
            widthLarge : window.innerWidth >= 600 ? true : false,
        }
    },
    mounted(){
        window.addEventListener('resize', ()=>{
            this.widthLarge = window.innerWidth >= 600 ? true : false;
        });
    },
}
</script>
<style>
    .marginTop{
        margin-top: var(--navbar-height); 
    }
    .index-holder{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;

        height: auto;
        width: 100%;

        overflow-y:hidden;
    }
</style>