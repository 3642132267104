<template>
  <router-view></router-view>
  <sideBar></sideBar>
</template>

<script>
import sideBar from './components/emitter/side-bar.vue';
export default {
  name: 'App',
  components: {
    sideBar
  }
}
</script>
<style src='./assets/css/main.css'></style>
<style>
*{
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  position: relative;
}

</style>
