<template>
    <indexLayout>
        <template #content>
            <div class="holder">
                <div class="matchsquare">
                    <p style="font-size:26px;font-weight:500;">Great, there’s a match 🎉 </p>
                    <p>
                        {{ matchMessage }}
                    </p>
                    <p style="font-weight:500;">
                        If you’re ready to move forward, click on the button below.
                    </p>
                    <router-link to="/questions/match/documents">
                        <actionButton InnerText="Upload documents"></actionButton>
                    </router-link>

                </div>

            </div>


        </template>
    </indexLayout>

</template>
<script>
import indexLayout from './index/index-layout.vue';
import actionButton from '@/components/index/action-button.vue';
// import { AuthTokenDelete } from '@/api.service';
export default {
    name: "match-view",
    data() {
        return {
            matchMessage: "We kindly request that you upload the necessary documents to allow our team to begin the process. Please note that we are compensated only after we have fully recovered your funds."
        }
    },
    components: {
        indexLayout,
        actionButton
    },
    beforeUnmount() {
        // AuthTokenDelete()
    },
    beforeMount(){
        if(!("AuthToken" in window.localStorage)){
            this.$router.replace({name:'index-not-found'})
        }
    }
}
</script>
<style >
.matchsquare {
    background-color: #f8f9fa;
    border: solid 1px #dee2e6;
    height: auto;
    width: 90vw;

    padding: 4%;
    box-sizing: border-box;

    border-radius: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6vh;

}
</style>